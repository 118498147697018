import http from "../http-common"; 

class LeadListService {
  getAllLeadLists(searchDTO) {
    console.log(searchDTO)
    return this.getRequest(`/leadList/leadLists`, searchDTO);
  }
 

  get(leadListId) {
    return this.getRequest(`/leadList/${leadListId}`, null);
  }

  findByField(matchData) {
    return this.getRequest(`/leadList?field=${matchData}`, null);
  }

  addLeadList(data) {
    return http.post("/leadList/addLeadList", data);
  }

  update(data) {
  	return http.post("/leadList/updateLeadList", data);
  }
  
  uploadImage(data,leadListId) {
  	return http.postForm("/leadList/uploadImage/"+leadListId, data);
  }




	postRequest(url, data) {
        const token = localStorage.getItem('authToken');
        if (token) {
            return http.post(url, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
        }
      };

	getRequest(url, params) {
        const token = localStorage.getItem('authToken');
        if (token) {
            return http.get(url, {
            	params: params,
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
        }
    };

}

export default new LeadListService();

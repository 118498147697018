import Vue from "vue";
import VueRouter from "vue-router";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import Dialer from  '@/pages/Dialer.vue';
import MyDashboard from  '@/pages/MyDashboard.vue';
import PhoneNumbers from  '@/pages/PhoneNumbers.vue';
import PhoneNumberDetail from  '@/pages/PhoneNumberDetail.vue';
import PhoneNumberAssignments from  '@/pages/PhoneNumberAssignments.vue';
import PhoneNumberAssignmentDetail from  '@/pages/PhoneNumberAssignmentDetail.vue';
import LeadLists from  '@/pages/LeadLists.vue';
import LeadListDetail from  '@/pages/LeadListDetail.vue';
import ActivityLogs from  '@/pages/ActivityLogs.vue';
import ActivityLogDetail from  '@/pages/ActivityLogDetail.vue';
import Carriers from  '@/pages/Carriers.vue';
import CarrierDetail from  '@/pages/CarrierDetail.vue';
import Leads from  '@/pages/Leads.vue';
import LeadDetail from  '@/pages/LeadDetail.vue';
import Calls from  '@/pages/Calls.vue';
import CallDetail from  '@/pages/CallDetail.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
		redirect: '/sign-in',
  },
  
  {
    path: '/dialer',
    name: 'Dialer',
    layout: DefaultLayout,
    component: Dialer,
    meta: { hideHeader: false },
  },

  {
    path: '/mydashboard',
    name: 'MyDashboard',
    layout: DefaultLayout,
    component: MyDashboard,
    meta: { hideHeader: false },
  },
 
  {
    path: "/sign-in",
	name: 'Sign-In',
	layout: DefaultLayout,
    component: () => import("../views/Sign-In.vue"),
    meta: { hideHeader: true },      
  },
  {
    path: "/sign-up",
	name: 'Sign-Up',
    component: () => import("../views/Sign-Up.vue"),
    meta: { hideHeader: true },      
  },
  {
    path: "/checkout",
	name: 'Checkout',
	layout: DefaultLayout,
    component: () => import("../views/Checkout.vue"),
    meta: { hideHeader: true },      
  },
  {
    path: "/checkout-cancel",
	name: 'Checkout-Cancel',
	layout: DefaultLayout,
    component: () => import("../views/Checkout-Cancel.vue"),
    meta: { hideHeader: true },      
  },
  {
    path: "/checkout-success",
	name: 'Checkout-Success',
	layout: DefaultLayout,
    component: () => import("../views/Checkout-Success.vue"),
    meta: { hideHeader: false },      
  },
  {
    path: "/pricing",
    name: "PricingView",
    component: () => import("../views/PricingView.vue"),
  },
  {
    path: "/checkout/:packageName",
    name: "CheckoutView",
    component: () => import("../views/Sign-Up.vue"),
    meta: { hideHeader: true },      
  },
  {
    path: "/stripe-checkout",
    name: "StripeCheckoutView",
    component: () => import("../views/StripeCheckoutView.vue"),
  },

	{
		path: '/phoneNumbers',
		name: 'PhoneNumbers',
		layout: DefaultLayout,
		component: PhoneNumbers,
	},
	{
	    path: '/phoneNumber/:phoneNumberId', 
	    name: 'PhoneNumberDetail',
		layout: DefaultLayout,
	    component: PhoneNumberDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/phoneNumberAssignments',
		name: 'PhoneNumberAssignments',
		layout: DefaultLayout,
		component: PhoneNumberAssignments,
	},
	{
	    path: '/phoneNumberAssignment/:phoneNumberAssignmentId', 
	    name: 'PhoneNumberAssignmentDetail',
		layout: DefaultLayout,
	    component: PhoneNumberAssignmentDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/leadLists',
		name: 'LeadLists',
		layout: DefaultLayout,
		component: LeadLists,
	},
	{
	    path: '/leadList/:leadListId', 
	    name: 'LeadListDetail',
		layout: DefaultLayout,
	    component: LeadListDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/activityLogs',
		name: 'ActivityLogs',
		layout: DefaultLayout,
		component: ActivityLogs,
	},
	{
	    path: '/activityLog/:activityLogId', 
	    name: 'ActivityLogDetail',
		layout: DefaultLayout,
	    component: ActivityLogDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/carriers',
		name: 'Carriers',
		layout: DefaultLayout,
		component: Carriers,
	},
	{
	    path: '/carrier/:carrierId', 
	    name: 'CarrierDetail',
		layout: DefaultLayout,
	    component: CarrierDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/leads',
		name: 'Leads',
		layout: DefaultLayout,
		component: Leads,
	},
	{
	    path: '/lead/:leadId', 
	    name: 'LeadDetail',
		layout: DefaultLayout,
	    component: LeadDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/calls',
		name: 'Calls',
		layout: DefaultLayout,
		component: Calls,
	},
	{
	    path: '/call/:callId', 
	    name: 'CallDetail',
		layout: DefaultLayout,
	    component: CallDetail,
	    props: true // Pass route params as props to the component
  	},
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;

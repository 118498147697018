import http from "../http-common"; 

class PhoneNumberService {
  getAllPhoneNumbers(searchDTO) {
    console.log(searchDTO)
    return this.getRequest(`/phoneNumber/phoneNumbers`, searchDTO);
  }
 

  get(phoneNumberId) {
    return this.getRequest(`/phoneNumber/${phoneNumberId}`, null);
  }

  findByField(matchData) {
    return this.getRequest(`/phoneNumber?field=${matchData}`, null);
  }

  addPhoneNumber(data) {
    return http.post("/phoneNumber/addPhoneNumber", data);
  }

  update(data) {
  	return http.post("/phoneNumber/updatePhoneNumber", data);
  }
  
  uploadImage(data,phoneNumberId) {
  	return http.postForm("/phoneNumber/uploadImage/"+phoneNumberId, data);
  }




	postRequest(url, data) {
        const token = localStorage.getItem('authToken');
        if (token) {
            return http.post(url, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
        }
      };

	getRequest(url, params) {
        const token = localStorage.getItem('authToken');
        if (token) {
            return http.get(url, {
            	params: params,
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
        }
    };

}

export default new PhoneNumberService();

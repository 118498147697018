    <template>
  <div class="content">

    <div class="call-details-container force-expansion"">
      <!-- Header Actions -->
      <header class="header-actions">
        <div class="action-buttons">
          <button class="btn btn-save" onclick="updateCall()">
            <i class="fas fa-save"></i> Save Changes
          </button>
          <button class="btn btn-add" onclick="toggleModal()">
            <i class="fas fa-plus"></i> Add Call
          </button>
        </div>
      </header>

      <!-- Main Content -->
      <div class="call-form">
        <div class="form-sections">
          <div class="sections-grid">
            <!-- Call Details Section -->
            <section class="form-section">
              <h3>Call Information</h3>
              <div class="form-stack">
                <div class="form-group">
                  <label>Call ID</label>
                  <input type="text" class="form-input" id="callId" readonly v-model="callDetails.callId">
                </div>
                <div class="form-group">
                  <label>Status</label>
                  <select id="status" class="form-select">
                    <option value="Scheduled">Scheduled</option>
                    <option value="In Progress">In Progress</option>
                    <option value="Completed">Completed</option>
                    <option value="Cancelled">Cancelled</option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Conversation Occurred</label>
                  <select id="conversationOccurred" class="form-select">
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Duration (minutes)</label>
                  <input type="number" class="form-input" id="duration" min="0" v-model="callDetails.callId">
                </div>
              </div>
            </section>

            <!-- Time Details Section -->
            <section class="form-section">
              <h3>Time Details</h3>
              <div class="form-stack">
                <div class="form-group">
                  <label>Created On</label>
                   <input type="text" disabled class="form-input" id="createdOn" v-model="callDetails.createdOn">
                </div>
                <div class="form-group">
                  <label>Ended On</label>
                  <input type="text" disabled class="form-input" id="endedOn" v-model="callDetails.endedOn">
                </div>
                <div class="form-group">
                  <label>Last Modified</label>
                  <input type="text" disabled class="form-input" id="lastModified" v-model="callDetails.lastModified">
                </div>
              </div>
            </section>
          </div>

          <!-- Notes Section -->
          <section class="form-section">
            <h3>Notes</h3>
            <div class="form-notes">
              <textarea id="notes" class="form-textarea" placeholder="Enter call notes here..." v-model="callDetails.notes"></textarea>
            </div>
          </section>
        </div>
      </div>
    </div>

  </div>
</template>


<script>

import CallService from "../services/CallService";
import Modal from "@/components/Modal";
import BaseButton from "@/components/BaseButton";
import BaseInput from "@/components/BaseInput";
import NotificationTemplate from "@/pages/Notifications/NotificationTemplate";
import { Card } from "@/components/Card";
import { ASelect, ASelectOption, AButton, Table, Pagination, message } from "ant-design-vue";
import { VueAutosuggest } from "vue-autosuggest";
import { UploadOutlined } from '@ant-design/icons-vue';
import Datepicker from 'vuejs-datepicker';


const tableColumns = [];
const tableData = [];
const fileList = ([]);
let file = ({});
const checked = (false);
let yearValue = ('');

export default {
  props: {
    callId: {
      type: String,
      required: true
    }
  },
  components: {
		Modal,
		BaseButton,
		BaseInput,
		VueAutosuggest,
		UploadOutlined,
		Datepicker,
  },
  data() {
    return {
      callToAdd: {},
      modal2Calls: false,
      callDetails: null,


      fileList,
      checked,
	  yearValue,

    };
  },
  methods: {

    

	handleSwitchChange(lifAnswerId, checked) {
	    this.answers.forEach(function(answer) {
	    	if (answer.questionType == '2') {
		    	if (answer.lifAnswerId == lifAnswerId) {
					answer.answerb = checked;
					if ( checked) {
						answer.answer = "Yes";
					} else {
						answer.answer = "No";
					}
				}
	    	}
		});
	},

 	handlePermissionDialogClose() {
      this.userPermissionDialog = false;
    },

    async handleAddSubmitted() {
      this.modal2Calls = false;

      const jsonData = JSON.stringify(this.callToAdd);
      console.log(jsonData);
      
      const res = await CallService.addCall(jsonData);

      if (res.status === 200) {
        this.$notify({
          component: NotificationTemplate,
          icon: "tim-icons icon-bell-55",
          type: "success",
          timeout: 3000,
        });
      }
	},

    async updateCall() {

      const jsonData = JSON.stringify(this.callDetails);
      const res = await CallService.update(jsonData);

      if (res.status === 200) {
//        this.$notify({
//          component: NotificationTemplate,
//          icon: "tim-icons icon-bell-55",
//          type: "success",
//          timeout: 3000,
//        });

//        this.modalCalls = false;
//        this.getAllCalls();
      }
    },


    async getCallDetails() {
      try {
			let response = await CallService.get(this.callId);
			this.callDetails = response.data;

			let hasAnswers = 0;

			if (hasAnswers) {			
		        this.answers.forEach(function(answer) {
		            if (answer.answer === "Yes") {
		               temp = true;
		            } else {
		               temp = false;
		            }
		        });
			}

	    
      } catch (error) {
        console.error('Error fetching call details:', error);
      }
    },

	formatTime(dateString) {
      if(dateString !== null){
        const date = new Date(dateString);
      return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')} EST`;
      }
      // Format the date here as needed, for example:
    },  
    
 formatDate(dateString) {
    if (dateString !== null) {
	    const date = new Date(dateString);
	    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	    const day = String(date.getDate()).padStart(2, '0');
	    const monthAbbreviation = months[date.getMonth()];
	    const year = date.getFullYear();
	    return `${day} ${monthAbbreviation} ${year}`;
  	}
  	// Handle the case when dateString is null or invalid
  	return '';
    
   },

  },
  mounted() {
    this.getCallDetails();
  },
  computed: {
  },
  
};
</script>


<style>

.detail_view_input input {
	text-align: center;
}

.detail_view_column_1 {
	width: 250px; 
	text-align: center;
}
.detail_view_column_2 {
	min-width: 250px; 
}
.input {
	text-align: center;
}
#autosuggest__input {
  display: inline-block;
}
.autosuggest__results-container {
	position: relative;
}
.autosuggest__results {
	font-weight: 300;
	margin: 0;
	position: absolute;
	z-index: 10000001;
	border: 1px solid #e0e0e0;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	background: white;
}
.autosuggest__results ul {
	list-style: none;
	padding-left: 0;
	margin: 0;
}
.autosuggest__results .autosuggest__results-item {
	cursor: pointer;
	padding: 5px;
}
#autosuggest ul:nth-child(1) > .autosuggest__results_title {
	border-top: none;
}
.autosuggest__results .autosuggest__results_title {
	color: gray;
	font-size: 11px;
	margin-left: 0;
	padding: 15px 13px 5px;
	border-top: 1px solid lightgray;
}
.autosuggest__results .autosuggest__results-item:active,
.autosuggest__results .autosuggest__results-item:hover,
.autosuggest__results .autosuggest__results-item:focus,
.autosuggest__results
.autosuggest__results-item.autosuggest__results-item--highlighted {
	background-color: #F6F6F6;
}

.call-image {
	    	height: 250px;
}

.inline-item {
  display: inline-block;
  margin-right: 100px; /* Adjust margin as needed */
}

.switch-container {
	width: 215px; 
    text-align: right;
    margin-right: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}

body {
  margin: 0;
  padding: 0;
  background: #f5f7fa;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.call-details-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.header-actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
}

.action-buttons {
  display: flex;
  gap: 1rem;
}

.btn {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.2s;
}

.btn-save {
  background: #4CAF50;
  color: white;
}

.btn-add {
  background: #2196F3;
  color: white;
}

.btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.form-sections {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.sections-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.form-section {
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.form-section h3 {
  margin: 0 0 1.5rem 0;
  color: #2c3e50;
  font-size: 1.25rem;
}

.form-stack {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  font-weight: 500;
  color: #4a5568;
  font-size: 0.875rem;
}

.form-input, .form-select {
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.2s;
}

.form-textarea {
  width: 100%;
  min-height: 150px;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  font-size: 1rem;
  resize: vertical;
  font-family: inherit;
}

.form-input:focus, .form-select:focus, .form-textarea:focus {
  outline: none;
  border-color: #2196F3;
  box-shadow: 0 0 0 3px rgba(33,150,243,0.1);
}

.form-input::placeholder, .form-textarea::placeholder {
  color: #a0aec0;
}

.form-notes {
  width: 100%;
}

.call-form input {
    border-radius: 15px;
    text-align: center;
}
</style>



	<template>
		<div class="content">

				<table>
					<tr> 
						<td style="width: 100%;"></td>
				        	<td style="width: 150px;">
				      			<base-button class="btn btn-primary" @click="updatePhoneNumber()">Save</base-button>
				        	</td>
				        	<td style="width: 150px;">
				        	<td style="width: 150px;">
				        		
								<div >
								  <div class="container">
												    <base-button class="btn btn-primary" @click="modal2PhoneNumbers = true">Add</base-button>
								              <modal :show.sync="modal2PhoneNumbers">
								                <template slot="header">
								                  <h5 class="modal-title" id="exampleModalLabel">Add PhoneNumber</h5>
								                </template>
								                <div>
								                  <form @submit.prevent>
				            				              <base-input label="FriendlyName" type="text" placeholder="Enter FriendlyName" v-model="phoneNumberToAdd.friendlyName"></base-input>
				            				              <base-input label="CreatedOn" type="text" placeholder="Enter CreatedOn" v-model="phoneNumberToAdd.createdOn"></base-input>
				            				              <base-input label="LastModified" type="text" placeholder="Enter LastModified" v-model="phoneNumberToAdd.lastModified"></base-input>
				            				              <base-input label="Status" type="text" placeholder="Enter Status" v-model="phoneNumberToAdd.status"></base-input>
				            				              <base-input label="State" type="text" placeholder="Enter State" v-model="phoneNumberToAdd.state"></base-input>
				            				              <base-input label="PhoneNumber" type="text" placeholder="Enter PhoneNumber" v-model="phoneNumberToAdd.phoneNumber"></base-input>
				            				              <base-input label="PhoneNumberId" type="text" placeholder="Enter PhoneNumberId" v-model="phoneNumberToAdd.phoneNumberId"></base-input>
				            								                  </form>
								                </div>
								                <template slot="footer">
								                  <base-button type="primary" @click="handleAddSubmitted()">Save</base-button>
								                </template>
								              </modal>
												  </div>
								</div>
				        	</td>
					</tr> 
				</table>



				<table>
      <template>
        <div class="content">
          <a-modal :footer="null" :visible="showProgressBar" class="centered-modal" v-if="showProgressBar"
                   :closable="false">
            <div style="text-align: center; padding: 20px;">
              <h5>Please wait...</h5>
            </div>
          </a-modal>
        </div>
        <div class="content">
          <a-modal :footer="null" :visible="userPermissionDialog" :closable="false" class="centered-modal" v-if="userPermissionDialog">
            <div style="text-align: center; padding: 20px;">
              <h5>User does not have permission</h5>
              <button @click="handlePermissionDialogClose">OK</button>
            </div>
          </a-modal>
        </div>
      </template>
	
										<tr> 
					<td class="detail_view_column_2">Phone Number Id</td>
										    <td class="detail_view_column_1">
							<div class="detail_view_input" style="display: inline-flex;">
																		<input label="PhoneNumberId" type="text" placeholder="Enter PhoneNumberId" v-model="phoneNumberDetails.phoneNumberId"></input>
																		</div>
						</td>
														</tr>
															<tr> 
					<td class="detail_view_column_2">Phone Number</td>
										    <td class="detail_view_column_1">
							<div class="detail_view_input" style="display: inline-flex;">
																		<input label="PhoneNumber" type="text" placeholder="Enter PhoneNumber" v-model="phoneNumberDetails.phoneNumber"></input>
																		</div>
						</td>
														</tr>
															<tr> 
					<td class="detail_view_column_2">Friendly Name</td>
										    <td class="detail_view_column_1">
							<div class="detail_view_input" style="display: inline-flex;">
																		<input label="FriendlyName" type="text" placeholder="Enter FriendlyName" v-model="phoneNumberDetails.friendlyName"></input>
																		</div>
						</td>
														</tr>
															<tr> 
					<td class="detail_view_column_2">State</td>
										    <td class="detail_view_column_1">
							<div class="detail_view_input" style="display: inline-flex;">
																		<input label="State" type="text" placeholder="Enter State" v-model="phoneNumberDetails.state"></input>
																		</div>
						</td>
														</tr>
															<tr> 
					<td class="detail_view_column_2">Status</td>
										    <td class="detail_view_column_1">
							<div class="detail_view_input" style="display: inline-flex;">
																		<input label="Status" type="text" placeholder="Enter Status" v-model="phoneNumberDetails.status"></input>
																		</div>
						</td>
														</tr>
															<tr> 
					<td class="detail_view_column_2">Created On</td>
										    <td class="detail_view_column_1">
							<div class="detail_view_input" style="display: inline-flex;">
																		<datepicker v-model="phoneNumberDetails.createdOn"  placeholder="Select Date" ></datepicker>
																		</div>
						</td>
														</tr>
															<tr> 
					<td class="detail_view_column_2">Last Modified</td>
										    <td class="detail_view_column_1">
							<div class="detail_view_input" style="display: inline-flex;">
																		<datepicker v-model="phoneNumberDetails.lastModified"  placeholder="Select Date" ></datepicker>
																		</div>
						</td>
														</tr>
						

	
		
		</table>
	
	




















			<a-tabs v-model:activeKey="activeKey">
											</a-tabs>

		</div>
	</template>

<script>

import PhoneNumberService from "../services/PhoneNumberService";
import Modal from "@/components/Modal";
import BaseButton from "@/components/BaseButton";
import BaseInput from "@/components/BaseInput";
import NotificationTemplate from "@/pages/Notifications/NotificationTemplate";
import { Card } from "@/components/Card";
import { ASelect, ASelectOption, AButton, Table, Pagination, message } from "ant-design-vue";
import { VueAutosuggest } from "vue-autosuggest";
import { UploadOutlined } from '@ant-design/icons-vue';
import Datepicker from 'vuejs-datepicker';


const tableColumns = [];
const tableData = [];
const fileList = ([]);
let file = ({});
const checked = (false);
let yearValue = ('');

export default {
  props: {
    phoneNumberId: {
      type: String,
      required: true
    }
  },
  components: {
		Modal,
		BaseButton,
		BaseInput,
		VueAutosuggest,
		UploadOutlined,
		Datepicker,
  },
  data() {
    return {
      phoneNumberToAdd: {},
      modal2PhoneNumbers: false,
      phoneNumberDetails: null,


      fileList,
      checked,
	  yearValue,

    };
  },
  methods: {

    

	handleSwitchChange(lifAnswerId, checked) {
	    this.answers.forEach(function(answer) {
	    	if (answer.questionType == '2') {
		    	if (answer.lifAnswerId == lifAnswerId) {
					answer.answerb = checked;
					if ( checked) {
						answer.answer = "Yes";
					} else {
						answer.answer = "No";
					}
				}
	    	}
		});
	},

 	handlePermissionDialogClose() {
      this.userPermissionDialog = false;
    },

    async handleAddSubmitted() {
      this.modal2PhoneNumbers = false;

      const jsonData = JSON.stringify(this.phoneNumberToAdd);
      console.log(jsonData);
      
      const res = await PhoneNumberService.addPhoneNumber(jsonData);

      if (res.status === 200) {
        this.$notify({
          component: NotificationTemplate,
          icon: "tim-icons icon-bell-55",
          type: "success",
          timeout: 3000,
        });
      }
	},

    async updatePhoneNumber() {

      const jsonData = JSON.stringify(this.phoneNumberDetails);
      const res = await PhoneNumberService.update(jsonData);

      if (res.status === 200) {
//        this.$notify({
//          component: NotificationTemplate,
//          icon: "tim-icons icon-bell-55",
//          type: "success",
//          timeout: 3000,
//        });

//        this.modalPhoneNumbers = false;
//        this.getAllPhoneNumbers();
      }
    },


    async getPhoneNumberDetails() {
      try {
			let response = await PhoneNumberService.get(this.phoneNumberId);
			this.phoneNumberDetails = response.data;

			let hasAnswers = 0;

			if (hasAnswers) {			
		        this.answers.forEach(function(answer) {
		            if (answer.answer === "Yes") {
		               temp = true;
		            } else {
		               temp = false;
		            }
		        });
			}

	    
      } catch (error) {
        console.error('Error fetching phoneNumber details:', error);
      }
    },

	formatTime(dateString) {
      if(dateString !== null){
        const date = new Date(dateString);
      return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')} EST`;
      }
      // Format the date here as needed, for example:
    },  
    
 formatDate(dateString) {
    if (dateString !== null) {
	    const date = new Date(dateString);
	    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	    const day = String(date.getDate()).padStart(2, '0');
	    const monthAbbreviation = months[date.getMonth()];
	    const year = date.getFullYear();
	    return `${day} ${monthAbbreviation} ${year}`;
  	}
  	// Handle the case when dateString is null or invalid
  	return '';
    
   },

  },
  mounted() {
    this.getPhoneNumberDetails();
  },
  computed: {
  },
  
};
</script>


<style>

.detail_view_input input {
	text-align: center;
}

.detail_view_column_1 {
	width: 250px; 
	text-align: center;
}
.detail_view_column_2 {
	min-width: 250px; 
}
.input {
	text-align: center;
}
#autosuggest__input {
  display: inline-block;
}
.autosuggest__results-container {
	position: relative;
}
.autosuggest__results {
	font-weight: 300;
	margin: 0;
	position: absolute;
	z-index: 10000001;
	border: 1px solid #e0e0e0;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	background: white;
}
.autosuggest__results ul {
	list-style: none;
	padding-left: 0;
	margin: 0;
}
.autosuggest__results .autosuggest__results-item {
	cursor: pointer;
	padding: 5px;
}
#autosuggest ul:nth-child(1) > .autosuggest__results_title {
	border-top: none;
}
.autosuggest__results .autosuggest__results_title {
	color: gray;
	font-size: 11px;
	margin-left: 0;
	padding: 15px 13px 5px;
	border-top: 1px solid lightgray;
}
.autosuggest__results .autosuggest__results-item:active,
.autosuggest__results .autosuggest__results-item:hover,
.autosuggest__results .autosuggest__results-item:focus,
.autosuggest__results
.autosuggest__results-item.autosuggest__results-item--highlighted {
	background-color: #F6F6F6;
}

.phoneNumber-image {
	    	height: 250px;
}

.inline-item {
  display: inline-block;
  margin-right: 100px; /* Adjust margin as needed */
}

.switch-container {
	width: 215px; 
    text-align: right;
    margin-right: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}

body {
  margin: 0;
  padding: 0;
  background: #f5f7fa;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.call-details-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.header-actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
}

.action-buttons {
  display: flex;
  gap: 1rem;
}

.btn {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.2s;
}

.btn-save {
  background: #4CAF50;
  color: white;
}

.btn-add {
  background: #2196F3;
  color: white;
}

.btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.form-sections {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.sections-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.form-section {
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.form-section h3 {
  margin: 0 0 1.5rem 0;
  color: #2c3e50;
  font-size: 1.25rem;
}

.form-stack {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  font-weight: 500;
  color: #4a5568;
  font-size: 0.875rem;
}

.form-input, .form-select {
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.2s;
}

.form-textarea {
  width: 100%;
  min-height: 150px;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  font-size: 1rem;
  resize: vertical;
  font-family: inherit;
}

.form-input:focus, .form-select:focus, .form-textarea:focus {
  outline: none;
  border-color: #2196F3;
  box-shadow: 0 0 0 3px rgba(33,150,243,0.1);
}

.form-input::placeholder, .form-textarea::placeholder {
  color: #a0aec0;
}

.form-notes {
  width: 100%;
}

.call-form input {
    border-radius: 15px;
    text-align: center;
}
</style>


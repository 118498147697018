<template>
  <div :class="['app-header', { hidden: hideHeader }]" ref="header" key="$route.path">

    <div class="top-left-corner-container">
	    <div class="logo-container" >
	      <router-link to="/mydashboard">
	    	<section class="section-1">
	    		<div class="title">
	                <img class="application-image" :src="`/images/reachit.jpg`" />
	    			<span class="application-title" data-desktop-color="#11ba81;" style="color: #11ba81;">Reachit</span>
				</div>
				<div class="subtitle">
				</div>
			</section>
	      </router-link>
		</div>
		<div class="search-container">
	        <a-input-search class="header-search" :class="searchLoading ? 'loading' : ''" placeholder="Search by Names, Companies, PhoneNumbers, …"
	          @search="onSearch" :loading='searchLoading' v-model="searchQuery">
	          <svg slot="prefix" width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
	            <path fill-rule="evenodd" clip-rule="evenodd"
	              d="M8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 9.29583 13.5892 10.4957 12.8907 11.4765L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3166 18.0976 16.6834 18.0976 16.2929 17.7071L11.4765 12.8907C10.4957 13.5892 9.29583 14 8 14C4.68629 14 2 11.3137 2 8Z"
	              fill="#111827" />
	          </svg>
	        </a-input-search>
		</div>
    </div>

    <!-- Add a button for the hamburger icon -->
    <div class="hamburger" >
	  <a-menu v-model:selectedKeys="current" mode="horizontal">
	    <a-sub-menu>
	      <template #icon>
	        <setting-outlined />
	      </template>
	      <template #title><div class="hamburger" >&#9776</div></template>
            <a-menu-item key="setting:1"><router-link class="link" to="/mydashboard">My Dashboard</router-link></a-menu-item>
            <a-menu-item key="setting:1"><router-link class="link" to="/sign-in">Sign In</router-link></a-menu-item>

	    </a-sub-menu>
	  </a-menu>
    </div>

    <div :class="['links', { 'links--active': isMenuOpen }]">

		<nav class="nav">
	        <router-link class="link" to="/mydashboard">My Dashboard</router-link>
		</nav>
    </div>
  </div>
</template>

<script>
export default {

  name: "AppHeader",

  props: ["hideHeader"],

  data() {
    return {
	  current: null,
      isMenuOpen: false,
	  searchQuery: '',
    };
  },

  computed: {

    isSignInPage() {
      // Check if the current route is '/sign-in'
      return this.$route.path === '/sign-in';
    },
  },

  watch: {

    searchQuery: {
      handler: "handleSearchQueryChanged", // Call the fetchData method when searchQuery changes
      immediate: true, // Trigger immediately when the component is mounted
    },
  },

  methods: {

    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },

	handleSearchQueryChanged() {
	  const currentRoute = this.$route.path;

   	  console.log("handleSearchQueryChanged CALLED FROM @search")
	  console.log(`Current route: ${currentRoute}`);

	  switch (currentRoute) {
	    case '/leads':	      			this.$root.$emit('searchQueryForLeadsChanged', this.searchQuery);	      break;
	    case '/phoneNumbers':	      	this.$root.$emit('searchQueryForPhoneNumbersChanged', this.searchQuery);	      break;
	    case '/phoneNumberAssignments':	this.$root.$emit('searchQueryForPhoneNumberAssignmentsChanged', this.searchQuery);	      break;
	    case '/calls':	      			this.$root.$emit('searchQueryForCallsChanged', this.searchQuery);	      break;
	    case '/leadLists':	      		this.$root.$emit('searchQueryForLeadListsChanged', this.searchQuery);	      break;
	    case '/activityLogs':	      	this.$root.$emit('searchQueryForActivityLogsChanged', this.searchQuery);	      break;
	    case '/users':	      			this.$root.$emit('searchQueryForUsersChanged', this.searchQuery);	      break;
	    default:	      console.warn(`No emit configured for route: ${currentRoute}`);	      break;
	  }
	},

    onSearch(value) {
      console.log(value);
      this.searchQuery = value; // Update searchQuery when the user types
    },
  },
};
</script>

</script>

<style scoped>
.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  box-shadow: 0px -10px 23px rgba(51, 51, 51, 0.2);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  background: #fff;
  flex-wrap: wrap;
  height: 80px; 
  transition: height 0.3s ease; 
}

.logo {
  height: 70px;
  cursor: pointer;
}

.links {
  display: flex;
  align-items: center;
}

.avator img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #ccc;
}

.hamburger {
  display: none;
  font-size: 30px;
  cursor: pointer;
}

.menu-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 80%;
  max-width: 300px;
}

.close {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 30px;
  cursor: pointer;
}

.popup-link {
  color: #444;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 18px;
  display: block;
  margin-top: 20px;
}

.popup-link:hover {
  text-decoration: underline;
  color: #0000ff;
}


/* Media queries to handle responsiveness */
@media (max-width: 768px) {
  .links {
    display: none;
    flex-direction: column;
    width: 100%;
    text-align: center;
  }
  .links--active {
    display: flex;
  }
  .hamburger {
    display: block;
  }
}

.link {
  margin-right: 1rem;
  color: #444;
/* text-transform: uppercase; */
  text-decoration: none;
  font-size: 14px;
}

.link:hover {
  color: #0000ff;
}

.link {
  margin-right: 1.5rem;
  color: #444;
/* text-transform: uppercase; */
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
}

.link:hover {
  color: #007bff;
}

.section-1 {
  line-height: 34px;
  background-color: white;
  color: rgb(0, 97, 249);
  text-align: center;
  font-weight: 500;
  opacity: 1;
  font-size: 42px;
  font-style: italic;
}

.section-1 .subtitle {
  background-color: white;
  color: rgb(0, 97, 249);
  text-align: center;
  font-weight: 400;
  opacity: 1;
  font-size: 21px;
  font-style: italic;
}

.application-image {
    height: 70px;
    margin-right: 7px;
}

.application-title {
    margin-top: 0; 
    padding-top: 5px;
    display: inline-block;
    vertical-align: middle;
}

.header-search {
  width: 300px !important;
  margin-left: auto !important;
}

::v-deep(.header-search input) {
  border-radius: 15px;
}

.search-container {
	margin-left: 50px;
    margin-top: 25px
}

.top-left-corner-container {
	display: flex;
}
</style>

import http from "../http-common"; 

class CarrierService {
  getAllCarriers(searchDTO) {
    console.log(searchDTO)
    return this.getRequest(`/carrier/carriers`, searchDTO);
  }
 

  get(carrierId) {
    return this.getRequest(`/carrier/${carrierId}`, null);
  }

  findByField(matchData) {
    return this.getRequest(`/carrier?field=${matchData}`, null);
  }

  addCarrier(data) {
    return http.post("/carrier/addCarrier", data);
  }

  update(data) {
  	return http.post("/carrier/updateCarrier", data);
  }
  
  uploadImage(data,carrierId) {
  	return http.postForm("/carrier/uploadImage/"+carrierId, data);
  }




	postRequest(url, data) {
        const token = localStorage.getItem('authToken');
        if (token) {
            return http.post(url, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
        }
      };

	getRequest(url, params) {
        const token = localStorage.getItem('authToken');
        if (token) {
            return http.get(url, {
            	params: params,
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
        }
    };

}

export default new CarrierService();


<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card>
          <template slot="header">
            <div class="row justify-content-between align-items-between mx-3">

              <h5 class="card-title">Calls</h5>
              
              <div>
                  <base-button class="btn btn-primary" @click="modalCalls = true">Add</base-button>
              </div>
              
              <modal :show.sync="modalCalls">
                <template slot="header">
                  <h5 class="modal-title" id="exampleModalLabel">Add Call</h5>
                </template>
                <div>
                  <form @submit.prevent>
  <base-input label="Notes" type="text" placeholder="Enter Notes" v-model="callToAdd.notes"></base-input>
  <base-input label="Duration" type="text" placeholder="Enter Duration" v-model="callToAdd.duration"></base-input>
  <base-input label="EndedOn" type="text" placeholder="Enter EndedOn" v-model="callToAdd.endedOn"></base-input>
  <base-input label="ConversationOccurred" type="text" placeholder="Enter ConversationOccurred" v-model="callToAdd.conversationOccurred"></base-input>
  <base-input label="Status" type="text" placeholder="Enter Status" v-model="callToAdd.status"></base-input>
  <base-input label="CallId" type="text" placeholder="Enter CallId" v-model="callToAdd.callId"></base-input>
  <base-input label="LastModified" type="text" placeholder="Enter LastModified" v-model="callToAdd.lastModified"></base-input>
  <base-input label="CreatedOn" type="text" placeholder="Enter CreatedOn" v-model="callToAdd.createdOn"></base-input>
                  </form>
                </div>
                <template slot="footer">
                  <base-button type="primary" @click="handleAddSubmitted()">Save</base-button>
                </template>
              </modal>
            </div>
          </template>
<div>
          <span class="table-title">Calls</span>
          <span>({{this.totalElements}})</span>
          <span><a-pagination
				      :current="pagination.current"
				      :total="pagination.total"
				      :pageSize="pagination.pageSize"
				      @change="onTableChange"
				      show-size-changer
				      :pageSizeOptions="['5','10', '20', '30', '40']"
				      @showSizeChange="handleSizeChange"
				    /></span>
</div>
          <!-- Conditionally render the view based on the 'isTableView' flag -->
          <div v-if="isTableView">
            <!-- Render the existing Table View -->
            <a-table :columns="columns" :data-source="calls" :row-key="record => record.CallId" :pagination="pagination"
              :loading="searchLoading" @change="onTableChange" :scroll="{ x: 'max-content' }">


              <template slot="callId" slot-scope="dataIndex">
                <a :href="'#call/' + dataIndex" target="_blank" class="call-link"><div>{{ dataIndex }}</div></a>
              </template>

	<template slot="conversationOccurred" slot-scope="dataIndex">
       {{ dataIndex ? 'Yes' : 'No' }}
   </template>

             <template slot="lastModified" slot-scope="dataIndex">
              	{{ formatTime(dataIndex) }}
              </template>
              <template slot="createdOn" slot-scope="dataIndex">
              	{{ formatTime(dataIndex) }}
              </template>
              <template slot="endedOn" slot-scope="dataIndex">
              	{{ formatTime(dataIndex) }}
              </template>
              <template slot="blackOutStartDate" slot-scope="dataIndex">
              	{{ formatDate(dataIndex) }}
              </template>
            </a-table>
          </div>
          <div v-else>
            <!-- Render the Picture View  -->
            <CallPictureView :calls="calls" />
          </div>

        </card>
      </div>
    </div>

  </div>
</template>

<script>
import Modal from "@/components/Modal";
import BaseButton from "@/components/BaseButton";
import BaseInput from "@/components/BaseInput";
import NotificationTemplate from "@/pages/Notifications/NotificationTemplate";
import { Card } from "@/components/Card";
import CallService from "../services/CallService";
import { ASelect, ASelectOption, AButton, Table, Pagination } from "ant-design-vue";
import CallPictureView from './CallPictureView.vue';


const callsColumns = [
  "callId",
  "callId"
]

export default {
  props: {
    calls: {
      type: Array,
      required: true,
    },
    totalElements: {
      type: Number,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
  },
  components: {
    Card,
    Modal,
    BaseButton,
    BaseInput,
    Table,

    Pagination,
    CallPictureView
  },

  data() {
    return {
      modalCalls: false,
        isTableView: true,

      columns: [
        {
          title: 'Call Id',
				dataIndex: 'callId',
	          visible: true,
          scopedSlots: { customRender: 'callId' },
          sorter: true
          //sorter: (a, b) => a.callId - b.callId,
          //sorter: (a, b) => a.callId.localeCompare(b.callId),
        },
        {
          title: 'Status',
		dataIndex: 'status',
          visible: true,
          scopedSlots: { customRender: 'status' },
          sorter: true
          //sorter: (a, b) => a.status - b.status,
          //sorter: (a, b) => a.status.localeCompare(b.status),
        },
        {
          title: 'Conversation Occurred',
		dataIndex: 'conversationOccurred',
          visible: true,
          scopedSlots: { customRender: 'conversationOccurred' },
          sorter: true
          //sorter: (a, b) => a.conversationOccurred - b.conversationOccurred,
          //sorter: (a, b) => a.conversationOccurred.localeCompare(b.conversationOccurred),
        },
        {
          title: 'Ended On',
		dataIndex: 'endedOn',
          visible: true,
          scopedSlots: { customRender: 'endedOn' },
          sorter: true
          //sorter: (a, b) => a.endedOn - b.endedOn,
          //sorter: (a, b) => a.endedOn.localeCompare(b.endedOn),
        },
        {
          title: 'Duration',
		dataIndex: 'duration',
          visible: true,
          scopedSlots: { customRender: 'duration' },
          sorter: true
          //sorter: (a, b) => a.duration - b.duration,
          //sorter: (a, b) => a.duration.localeCompare(b.duration),
        },
        {
          title: 'Notes',
		dataIndex: 'notes',
          visible: true,
          scopedSlots: { customRender: 'notes' },
          sorter: true
          //sorter: (a, b) => a.notes - b.notes,
          //sorter: (a, b) => a.notes.localeCompare(b.notes),
        },
        {
          title: 'Created On',
		dataIndex: 'createdOn',
          visible: true,
          scopedSlots: { customRender: 'createdOn' },
          sorter: true
          //sorter: (a, b) => a.createdOn - b.createdOn,
          //sorter: (a, b) => a.createdOn.localeCompare(b.createdOn),
        },
        {
          title: 'Last Modified',
		dataIndex: 'lastModified',
          visible: true,
          scopedSlots: { customRender: 'lastModified' },
          sorter: true
          //sorter: (a, b) => a.lastModified - b.lastModified,
          //sorter: (a, b) => a.lastModified.localeCompare(b.lastModified),
        },
      ],
      pagination: {
        current: 1,
        pageSize: 50,
        total: 0,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total) => `Total ${total} calls`,
      },

      calls: [],
      callToAdd: {},

      callsTable: {
        columns: [...callsColumns],
        data: [],
      },

      // New properties for sorting and searching
      sortBy: 'callId',           // Column to sort by
      sortOrder: 'asc',     // Sort order (asc or desc)
      searchLoading: false, // Initialize searchLoading property


    };
  },

  methods: {

    async renderCallsTable() {
      this.searchLoading = true; // Set searchLoading to true while fetching data
      this.searchLoading = false;

      this.pagination.total = this.totalElements;
      this.pagination.current = this.page;

      let callsTableData = [];
      for (let i = 0; i < this.calls.length; i++) {
        callsTableData.push({
          id: i,
          callId: this.calls[i].callId,
          callId: this.calls[i].callId,
        });

      }
      this.searchLoading = false;
    },

    async onTableChange(pagination, filters, sorter) {
      if (sorter && sorter.field && sorter.order) {
        this.sortBy = sorter.field;
        if (sorter.order == "ascend") {
            this.sortOrder = "asc";
        } else {
            this.sortOrder = "desc";
        }
      }
      if (pagination) {
        this.pagination.current = pagination.current;
        this.pagination.pageSize = pagination.pageSize;
      }

	  this.$emit('get-all-calls',this.sortBy,this.sortOrder,this.pagination.current-1,this.pagination.pageSize);
      this.handleTableChanged()
    },
	
	initializeColumns() {
        this.columns = this.columns.filter(item => item.visible);
    },

    routingToPhoneNumberDetail(id) {
      this.$router.push({ name: 'PhoneNumberDetail', params: { phoneNumberId: id.toString() }})
    },
    routingToPhoneNumberAssignmentDetail(id) {
      this.$router.push({ name: 'PhoneNumberAssignmentDetail', params: { phoneNumberAssignmentId: id.toString() }})
    },
    routingToLeadListDetail(id) {
      this.$router.push({ name: 'LeadListDetail', params: { leadListId: id.toString() }})
    },
    routingToActivityLogDetail(id) {
      this.$router.push({ name: 'ActivityLogDetail', params: { activityLogId: id.toString() }})
    },
    routingToCarrierDetail(id) {
      this.$router.push({ name: 'CarrierDetail', params: { carrierId: id.toString() }})
    },
    routingToLeadDetail(id) {
      this.$router.push({ name: 'LeadDetail', params: { leadId: id.toString() }})
    },
    routingToCallDetail(id) {
      this.$router.push({ name: 'CallDetail', params: { callId: id.toString() }})
    },
    
    toggleView() {
      this.isTableView = !this.isTableView;
    },
	
	async handleAddSubmitted() {
      this.modalCalls = false;

      const currentDate = new Date().getTime();
      this.callToAdd.created = currentDate;

      const jsonData = JSON.stringify(this.callToAdd);
      console.log(jsonData);
      
      const res = await CallService.addCall(jsonData);

      if (res.status === 200) {
        this.$notify({
          component: NotificationTemplate,
          icon: "tim-icons icon-bell-55",
          type: "success",
          timeout: 3000,
        });
      }
	},
	
	handleTableChanged() {
	},
	
	formatTime(dateString) {
      if(dateString !== null){
        const date = new Date(dateString);
      return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')} EST`;
      }
      // Format the date here as needed, for example:
    },  
    
 formatDate(dateString) {
    if (dateString !== null) {
	    const date = new Date(dateString);
	    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	    const day = String(date.getDate()).padStart(2, '0');
	    const monthAbbreviation = months[date.getMonth()];
	    const year = date.getFullYear();
	    return `${day} ${monthAbbreviation} ${year}`;
  	}
  	// Handle the case when dateString is null or invalid
  	return '';
    
   },

  },
  mounted() {
  	this.initializeColumns();
    this.renderCallsTable();
  }
};
</script>

<style>
.modal-dialog {
  margin-top: -300px;
}
.ant-table-row  {
	text-align: center;
}
.ant-table-thead th span {
	text-align: center;
	width: 100%
}
.ant-table-fixed td span {
    text-align: center;
}

.table-title {
	font-size: 1.75rem;
	font-weight: bold;
	font-style: italic;
	color: #11ba81;
	text-align: left;
	padding: 0px 15px;
}

.call-link {
	color: #11ba81;
}

</style>

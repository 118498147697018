<template> 



    <div>
        <div class="row card-container">
            <div class="col lead-card" v-for="lead in leads" :key="lead.leadId">
                <div class="lead-info">
                        <div class="lead-name"><a :href="'#lead/' +  lead.leadId"  target="_blank" class="lead-link">{{ lead.fullName }}</a></div>
                        <div class="lead-detail">{{ lead.jobTitle }}</div>
                        <div class="lead-detail">{{ lead.company }}</div>
                        <div class="lead-detail">{{ lead.phone }}</div>
                        <div class="lead-detail">{{ lead.email }}</div>
                </div>
            </div>
        </div>
    </div>



</template>
  
<script>

export default {
    components: {
    },
    props: {
        leads: Array,
    },

    methods: {

    },
};
</script>
  
<style scoped>
.card-container {
    display: flex;
    column-gap: 15px;
    flex-wrap: wrap;
    margin: 5px;

}

.card {
    max-width: 230px;

}

.lead-picture-view {
    margin-bottom: 15px;
    /* Separate each row from the content below */
}

.lead-card {
    max-width: 250px;
    min-width: 250px;
    border-radius: 30px;
    padding: 0px;
    margin-bottom: 25px;
    /* Separate each card */
}

.lead-image {
				    	width: 250px;
	    	height: 200px;
	    	        	border-radius: 4px;
    }

.lead-info {
    margin-top: 0px;
}

.lead-name {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 2px;
    line-height: 1.2;
}

.lead-details p {
    margin-top: 0px;
    text-align: left;
}

.lead-link {
    display: inline;
    margin-top: 5px;
    color: rgb(0, 180, 0);;
}

.lead-link {
    display: inline;
    margin-top: 5px;
    color: #11ba81;
}

.lead-card {
  background: white;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.2s;
  cursor: pointer;
}

.lead-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

.lead-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.lead-info {
  padding: 1rem;
}

.lead-name {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.lead-detail {
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 0.25rem;
}
</style>
  

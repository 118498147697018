    <template>
  <div class="content">

    <div class="lead-details-container force-expansion">
      <!-- Header Actions -->
      <header class="header-actions">
        <div class="action-buttons">
          <button class="btn btn-save" onclick="updateLead()">
            <i class="fas fa-save"></i> Save Changes
          </button>
          <button class="btn btn-add" onclick="toggleModal()">
            <i class="fas fa-plus"></i> Add Lead  
          </button>
        </div>
      </header>

      <!-- Main Content -->
      <div class="lead-form">
        <div class="form-sections">
          <!-- Basic Info Section -->
          <section class="form-section">
            <h3>Basic Information</h3>
            <div class="form-grid">
              <div class="form-group">
                <label>Lead ID</label>
                <input type="text" class="form-input" id="leadId" v-model="leadDetails.leadId" readonly>
              </div>
              <div class="form-group">
                <label>Name</label>
                <input type="text" class="form-input" id="name" v-model="leadDetails.fullName" placeholder="Enter Name">
              </div>
              <div class="form-group">
                <label>Email</label>
                <input type="email" class="form-input" id="email" v-model="leadDetails.email" placeholder="Enter Email">
              </div>
              <div class="form-group">
                <label>Phone</label>
                <input type="text" class="form-input" id="phone" v-model="leadDetails.phone" placeholder="Enter Phone">
              </div>
            </div>
          </section>

          <!-- Company Info Section -->
          <section class="form-section">
            <h3>Company Information</h3>
            <div class="form-grid">
              <div class="form-group">
                <label>Company</label>
                <input type="text" class="form-input" id="company" v-model="leadDetails.company" placeholder="Enter Company">
              </div>
              <div class="form-group">
                <label>Title</label>
                <input type="text" class="form-input" id="title" v-model="leadDetails.jobTitle" placeholder="Enter Title">
              </div>
              <div class="form-group">
                <label>Industry</label>
                <input type="text" class="form-input" id="industry" v-model="leadDetails.industry" placeholder="Enter Industry">
              </div>
              <div class="form-group">
                <label>Company Size</label>
                <input type="text" class="form-input" id="companySize" v-model="leadDetails.companySize" placeholder="Enter Company Size">
              </div>
            </div>
          </section>

          <!-- Additional Details Section -->  
          <section class="form-section">
            <h3>Additional Details</h3>
            <div class="form-grid">
              <div class="form-group">
                <label>LinkedIn URL</label>
                <input type="text" class="form-input" id="linkedinUrl" v-model="leadDetails.linkedinUrl" placeholder="Enter LinkedIn URL">
              </div>
              <div class="form-group">
                <label>Location</label>
                <input type="text" class="form-input" id="location" v-model="leadDetails.location" placeholder="Enter Location">
              </div>
              <div class="form-group">
                <label>Status</label>
                <select id="status" class="form-select">
                  <option value="New">New</option>
                  <option value="Contacted">Contacted</option>
                  <option value="Qualified">Qualified</option>
                  <option value="Lost">Lost</option>
                </select>
              </div>
              <div class="form-group">
                <label>Source</label>
                <input type="text" class="form-input" id="source" v-model="leadDetails.source" placeholder="Enter Source">
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>

  </div>
</template>




<script>

import LeadService from "../services/LeadService";
import Modal from "@/components/Modal";
import BaseButton from "@/components/BaseButton";
import BaseInput from "@/components/BaseInput";
import NotificationTemplate from "@/pages/Notifications/NotificationTemplate";
import { Card } from "@/components/Card";
import { ASelect, ASelectOption, AButton, Table, Pagination, message } from "ant-design-vue";
import { VueAutosuggest } from "vue-autosuggest";
import { UploadOutlined } from '@ant-design/icons-vue';
import Datepicker from 'vuejs-datepicker';


const tableColumns = [];
const tableData = [];
const fileList = ([]);
let file = ({});
const checked = (false);
let yearValue = ('');

export default {
  props: {
    leadId: {
      type: String,
      required: true
    }
  },
  components: {
		Modal,
		BaseButton,
		BaseInput,
		VueAutosuggest,
		UploadOutlined,
		Datepicker,
  },
  data() {
    return {
      leadToAdd: {},
      modal2Leads: false,
      leadDetails: null,


      fileList,
      checked,
	  yearValue,

    };
  },
  methods: {

    

	handleSwitchChange(lifAnswerId, checked) {
	    this.answers.forEach(function(answer) {
	    	if (answer.questionType == '2') {
		    	if (answer.lifAnswerId == lifAnswerId) {
					answer.answerb = checked;
					if ( checked) {
						answer.answer = "Yes";
					} else {
						answer.answer = "No";
					}
				}
	    	}
		});
	},

 	handlePermissionDialogClose() {
      this.userPermissionDialog = false;
    },

    async handleAddSubmitted() {
      this.modal2Leads = false;

      const jsonData = JSON.stringify(this.leadToAdd);
      console.log(jsonData);
      
      const res = await LeadService.addLead(jsonData);

      if (res.status === 200) {
        this.$notify({
          component: NotificationTemplate,
          icon: "tim-icons icon-bell-55",
          type: "success",
          timeout: 3000,
        });
      }
	},

    async updateLead() {

      const jsonData = JSON.stringify(this.leadDetails);
      const res = await LeadService.update(jsonData);

      if (res.status === 200) {
//        this.$notify({
//          component: NotificationTemplate,
//          icon: "tim-icons icon-bell-55",
//          type: "success",
//          timeout: 3000,
//        });

//        this.modalLeads = false;
//        this.getAllLeads();
      }
    },


    async getLeadDetails() {
      try {
			let response = await LeadService.get(this.leadId);
			this.leadDetails = response.data;

			let hasAnswers = 0;

			if (hasAnswers) {			
		        this.answers.forEach(function(answer) {
		            if (answer.answer === "Yes") {
		               temp = true;
		            } else {
		               temp = false;
		            }
		        });
			}

	    
      } catch (error) {
        console.error('Error fetching lead details:', error);
      }
    },

	formatTime(dateString) {
      if(dateString !== null){
        const date = new Date(dateString);
      return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')} EST`;
      }
      // Format the date here as needed, for example:
    },  
    
 formatDate(dateString) {
    if (dateString !== null) {
	    const date = new Date(dateString);
	    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	    const day = String(date.getDate()).padStart(2, '0');
	    const monthAbbreviation = months[date.getMonth()];
	    const year = date.getFullYear();
	    return `${day} ${monthAbbreviation} ${year}`;
  	}
  	// Handle the case when dateString is null or invalid
  	return '';
    
   },

  },
  mounted() {
    this.getLeadDetails();
  },
  computed: {
  },
  
};
</script>

<style>
.lead-details-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.header-actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
}

.action-buttons {
  display: flex;
  gap: 1rem;
}

.btn {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.2s;
}

.btn-save {
  background: #4CAF50;
  color: white;
}

.btn-add {
  background: #2196F3;
  color: white;
}

.btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.form-sections {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.form-section {
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.form-section h3 {
  margin: 0 0 1.5rem 0;
  color: #2c3e50;
  font-size: 1.25rem;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  font-weight: 500;
  color: #4a5568;
  font-size: 0.875rem;
}

.form-input, .form-select {
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.2s;
}

.form-input:focus, .form-select:focus {
  outline: none;
  border-color: #2196F3;
  box-shadow: 0 0 0 3px rgba(33,150,243,0.1);
}

.form-input::placeholder {
  color: #a0aec0;
}

.force-expansion {
  width: calc(100vw - 260px);
}
</style>


import http from "../http-common"; 

class PhoneNumberAssignmentService {
  getAllPhoneNumberAssignments(searchDTO) {
    console.log(searchDTO)
    return this.getRequest(`/phoneNumberAssignment/phoneNumberAssignments`, searchDTO);
  }
 

  get(phoneNumberAssignmentId) {
    return this.getRequest(`/phoneNumberAssignment/${phoneNumberAssignmentId}`, null);
  }

  findByField(matchData) {
    return this.getRequest(`/phoneNumberAssignment?field=${matchData}`, null);
  }

  addPhoneNumberAssignment(data) {
    return http.post("/phoneNumberAssignment/addPhoneNumberAssignment", data);
  }

  update(data) {
  	return http.post("/phoneNumberAssignment/updatePhoneNumberAssignment", data);
  }
  
  uploadImage(data,phoneNumberAssignmentId) {
  	return http.postForm("/phoneNumberAssignment/uploadImage/"+phoneNumberAssignmentId, data);
  }




	postRequest(url, data) {
        const token = localStorage.getItem('authToken');
        if (token) {
            return http.post(url, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
        }
      };

	getRequest(url, params) {
        const token = localStorage.getItem('authToken');
        if (token) {
            return http.get(url, {
            	params: params,
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
        }
    };

}

export default new PhoneNumberAssignmentService();

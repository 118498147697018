import { render, staticRenderFns } from "./CarrierTable.vue?vue&type=template&id=71a4e268"
import script from "./CarrierTable.vue?vue&type=script&lang=js"
export * from "./CarrierTable.vue?vue&type=script&lang=js"
import style0 from "./CarrierTable.vue?vue&type=style&index=0&id=71a4e268&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
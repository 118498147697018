<template>
  <div class="content">

    <div class="dashboard-title">My Dashboard </div>

    <div class="dashboard-main-content">
      <div class="dashboard-content force-expansion">
        <div class="stats-grid">
          <div class="stat-card" v-for="stat in stats" :key="stat.label">
            <div class="stat-value">{{ stat.value }}</div>
            <div>{{ stat.label }}</div>
          </div>
        </div>

        <div class="dashboard-table-container">
          <h2 class="dashboard-table-title">Recent Leads</h2>
          
          <table class="table">
            <thead>
              <tr>
                <th v-for="header in leadHeaders" :key="header">{{ header }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="lead in leads" :key="lead.name" @click="showRecordDetails(lead)">
                <td>{{ lead.name }}</td>
                <td>{{ lead.company }}</td>
                <td>{{ lead.status }}</td>
                <td>{{ lead.createdDate }}</td>
              </tr>
            </tbody>
          </table>
        </div>
            
        <div class="dashboard-table-container">
          <h2 class="dashboard-table-title">Tasks Due Today</h2>
          <table class="table">
            <thead>
              <tr>
                <th v-for="header in taskHeaders" :key="header">{{ header }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="task in tasks" :key="task.subject" @click="showRecordDetails(task)">
                <td>{{ task.subject }}</td>
                <td>{{ task.relatedTo }}</td>
                <td>{{ task.dueDate }}</td>
                <td>{{ task.priority }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      stats: [{
        value: '127',
        label: 'Open Leads'
      }, {
        value: '43',
        label: 'Opportunities'
      }, {
        value: '$1.2M',
        label: 'Pipeline Value'
      }, {
        value: '89%',
        label: 'Task Completion'
      }],
      leadHeaders: ['Name', 'Company', 'Status', 'Created Date'],
      leads: [{
        name: 'John Smith',
        company: 'Acme Corp',
        status: 'New',
        createdDate: '2023-07-20'
      }, {
        name: 'Sarah Johnson',
        company: 'Tech Solutions',
        status: 'Contacted',
        createdDate: '2023-07-19'
      }, {
        name: 'Michael Brown',
        company: 'Global Industries',
        status: 'Qualified',
        createdDate: '2023-07-18'
      }],
      taskHeaders: ['Subject', 'Related To', 'Due Date', 'Priority'],
      tasks: [{
        subject: 'Follow up call',
        relatedTo: 'John Smith',
        dueDate: 'Today',
        priority: 'High'
      }, {
        subject: 'Send proposal',
        relatedTo: 'Tech Solutions',
        dueDate: 'Today',
        priority: 'Normal'
      }],
    };
  },
  methods: {
  }
};
</script>

<style>

.dashboard-main-content {
  display: flex; /* Use flex to stretch across */
  flex-direction: column; /* Allow column-based structure */
  width: 100%; /* Make sure it's taking up full width */
  margin-left: auto; /* Ensure it aligns properly with the layout */
  margin-right: auto;
}

.dashboard-content {
    padding: 25px;
    width: 100%;
    overflow-x: auto;
    background: LightGray;
    border-radius: 15px;
}

.dashboard-table-container {
  background: white;
  border-radius: 15px;
  padding: 1.5rem;
  margin-top: 1rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Responsively sized grid items */
  gap: 1rem;
}

.stat-card {
  background: white;
  padding: 1rem;
  border-radius: 15px;
  text-align: center;
}

.stat-value {
  font-size: 2rem; 
  color: #11ba81;
  font-weight: bold;
}
.search-bar {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 300px;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 0.75rem;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

tr:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.force-expansion {
  width: calc(100vw - 260px);
}

.dashboard-title {
  font-size: 1.75rem;
  font-weight: bold;
  font-style: italic;
  color: #11ba81;
  text-align: left;
  padding: 0px 15px;
}

.dashboard-table-title {
  color: #11ba81;
  font-weight: bold;
  text-align: left;
}

</style>


<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card>
          <template slot="header">
            <div class="row justify-content-between align-items-between mx-3">

              <h5 class="card-title">Lead Lists</h5>
              
              <div>
                  <base-button class="btn btn-primary" @click="modalLeadLists = true">Add</base-button>
              </div>
              
              <modal :show.sync="modalLeadLists">
                <template slot="header">
                  <h5 class="modal-title" id="exampleModalLabel">Add LeadList</h5>
                </template>
                <div>
                  <form @submit.prevent>
  <base-input label="CreatedOn" type="text" placeholder="Enter CreatedOn" v-model="leadListToAdd.createdOn"></base-input>
  <base-input label="LeadListId" type="text" placeholder="Enter LeadListId" v-model="leadListToAdd.leadListId"></base-input>
  <base-input label="Name" type="text" placeholder="Enter Name" v-model="leadListToAdd.name"></base-input>
                  </form>
                </div>
                <template slot="footer">
                  <base-button type="primary" @click="handleAddSubmitted()">Save</base-button>
                </template>
              </modal>
            </div>
          </template>
<div>
          <span class="table-title">LeadLists</span>
          <span>({{this.totalElements}})</span>
          <span><a-pagination
				      :current="pagination.current"
				      :total="pagination.total"
				      :pageSize="pagination.pageSize"
				      @change="onTableChange"
				      show-size-changer
				      :pageSizeOptions="['5','10', '20', '30', '40']"
				      @showSizeChange="handleSizeChange"
				    /></span>
</div>
          <!-- Conditionally render the view based on the 'isTableView' flag -->
          <div v-if="isTableView">
            <!-- Render the existing Table View -->
            <a-table :columns="columns" :data-source="leadLists" :row-key="record => record.LeadListId" :pagination="pagination"
              :loading="searchLoading" @change="onTableChange" :scroll="{ x: 'max-content' }">




             <template slot="lastModified" slot-scope="dataIndex">
              	{{ formatTime(dataIndex) }}
              </template>
              <template slot="createdOn" slot-scope="dataIndex">
              	{{ formatTime(dataIndex) }}
              </template>
              <template slot="endedOn" slot-scope="dataIndex">
              	{{ formatTime(dataIndex) }}
              </template>
              <template slot="blackOutStartDate" slot-scope="dataIndex">
              	{{ formatDate(dataIndex) }}
              </template>
            </a-table>
          </div>
          <div v-else>
            <!-- Render the Picture View  -->
            <LeadListPictureView :leadLists="leadLists" />
          </div>

        </card>
      </div>
    </div>

  </div>
</template>

<script>
import Modal from "@/components/Modal";
import BaseButton from "@/components/BaseButton";
import BaseInput from "@/components/BaseInput";
import NotificationTemplate from "@/pages/Notifications/NotificationTemplate";
import { Card } from "@/components/Card";
import LeadListService from "../services/LeadListService";
import { ASelect, ASelectOption, AButton, Table, Pagination } from "ant-design-vue";
import LeadListPictureView from './LeadListPictureView.vue';


const leadListsColumns = [
  "leadListId",
  "leadListId"
]

export default {
  props: {
    leadLists: {
      type: Array,
      required: true,
    },
    totalElements: {
      type: Number,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
  },
  components: {
    Card,
    Modal,
    BaseButton,
    BaseInput,
    Table,

    Pagination,
    LeadListPictureView
  },

  data() {
    return {
      modalLeadLists: false,
        isTableView: true,

      columns: [
        {
          title: 'Lead List Id',
		dataIndex: 'leadListId',
          visible: true,
          scopedSlots: { customRender: 'leadListId' },
          sorter: true
          //sorter: (a, b) => a.leadListId - b.leadListId,
          //sorter: (a, b) => a.leadListId.localeCompare(b.leadListId),
        },
        {
          title: 'Name',
		dataIndex: 'name',
          visible: true,
          scopedSlots: { customRender: 'name' },
          sorter: true
          //sorter: (a, b) => a.name - b.name,
          //sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
          title: 'Created On',
		dataIndex: 'createdOn',
          visible: true,
          scopedSlots: { customRender: 'createdOn' },
          sorter: true
          //sorter: (a, b) => a.createdOn - b.createdOn,
          //sorter: (a, b) => a.createdOn.localeCompare(b.createdOn),
        },
      ],
      pagination: {
        current: 1,
        pageSize: 50,
        total: 0,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total) => `Total ${total} leadLists`,
      },

      leadLists: [],
      leadListToAdd: {},

      leadListsTable: {
        columns: [...leadListsColumns],
        data: [],
      },

      // New properties for sorting and searching
      sortBy: 'leadListId',           // Column to sort by
      sortOrder: 'asc',     // Sort order (asc or desc)
      searchLoading: false, // Initialize searchLoading property


    };
  },

  methods: {

    async renderLeadListsTable() {
      this.searchLoading = true; // Set searchLoading to true while fetching data
      this.searchLoading = false;

      this.pagination.total = this.totalElements;
      this.pagination.current = this.page;

      let leadListsTableData = [];
      for (let i = 0; i < this.leadLists.length; i++) {
        leadListsTableData.push({
          id: i,
          leadListId: this.leadLists[i].leadListId,
          leadListId: this.leadLists[i].leadListId,
        });

      }
      this.searchLoading = false;
    },

    async onTableChange(pagination, filters, sorter) {
      if (sorter && sorter.field && sorter.order) {
        this.sortBy = sorter.field;
        if (sorter.order == "ascend") {
            this.sortOrder = "asc";
        } else {
            this.sortOrder = "desc";
        }
      }
      if (pagination) {
        this.pagination.current = pagination.current;
        this.pagination.pageSize = pagination.pageSize;
      }

	  this.$emit('get-all-lead-lists',this.sortBy,this.sortOrder,this.pagination.current-1,this.pagination.pageSize);
      this.handleTableChanged()
    },
	
	initializeColumns() {
        this.columns = this.columns.filter(item => item.visible);
    },

    routingToPhoneNumberDetail(id) {
      this.$router.push({ name: 'PhoneNumberDetail', params: { phoneNumberId: id.toString() }})
    },
    routingToPhoneNumberAssignmentDetail(id) {
      this.$router.push({ name: 'PhoneNumberAssignmentDetail', params: { phoneNumberAssignmentId: id.toString() }})
    },
    routingToLeadListDetail(id) {
      this.$router.push({ name: 'LeadListDetail', params: { leadListId: id.toString() }})
    },
    routingToActivityLogDetail(id) {
      this.$router.push({ name: 'ActivityLogDetail', params: { activityLogId: id.toString() }})
    },
    routingToCarrierDetail(id) {
      this.$router.push({ name: 'CarrierDetail', params: { carrierId: id.toString() }})
    },
    routingToLeadDetail(id) {
      this.$router.push({ name: 'LeadDetail', params: { leadId: id.toString() }})
    },
    routingToCallDetail(id) {
      this.$router.push({ name: 'CallDetail', params: { callId: id.toString() }})
    },
    
    toggleView() {
      this.isTableView = !this.isTableView;
    },
	
	async handleAddSubmitted() {
      this.modalLeadLists = false;

      const currentDate = new Date().getTime();
      this.leadListToAdd.created = currentDate;

      const jsonData = JSON.stringify(this.leadListToAdd);
      console.log(jsonData);
      
      const res = await LeadListService.addLeadList(jsonData);

      if (res.status === 200) {
        this.$notify({
          component: NotificationTemplate,
          icon: "tim-icons icon-bell-55",
          type: "success",
          timeout: 3000,
        });
      }
	},
	
	handleTableChanged() {
	},
	
	formatTime(dateString) {
      if(dateString !== null){
        const date = new Date(dateString);
      return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')} EST`;
      }
      // Format the date here as needed, for example:
    },  
    
 formatDate(dateString) {
    if (dateString !== null) {
	    const date = new Date(dateString);
	    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	    const day = String(date.getDate()).padStart(2, '0');
	    const monthAbbreviation = months[date.getMonth()];
	    const year = date.getFullYear();
	    return `${day} ${monthAbbreviation} ${year}`;
  	}
  	// Handle the case when dateString is null or invalid
  	return '';
    
   },

  },
  mounted() {
  	this.initializeColumns();
    this.renderLeadListsTable();
  }
};
</script>

<style>
.modal-dialog {
  margin-top: -300px;
}
.ant-table-row  {
	text-align: center;
}
.ant-table-thead th span {
	text-align: center;
	width: 100%
}
.ant-table-fixed td span {
    text-align: center;
}

.table-title {
	font-size: 1.75rem;
	font-weight: bold;
	font-style: italic;
	color: #11ba81;
	text-align: left;
	padding: 0px 15px;
}

.leadList-link {
	color: #11ba81;
}

</style>

<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card class="card-plain">
          <!-- <template slot="header">
            <h4 class="card-title">Table on Plain Background</h4>
            <p class="category">Here is a subtitle for this table</p>
          </template>-->
          <div class="table-full-width text-left">
            <phoneNumberAssignment-table
            v-if="phoneNumberAssignments && phoneNumberAssignments.length > 0"
				:title="table1.title"
				:sub-title="table1.subTitle"
				:phoneNumberAssignments="phoneNumberAssignments"
				:totalElements="totalElements"
				:page="page"
				:columns="table1.columns"
 				@get-all-phone-number-assignments="getAllPhoneNumberAssignments"
             >

            </phoneNumberAssignment-table>
          </div>
        </card>
      </div>

    </div>
  </div>
</template>
<script>
import { Card } from "@/components/Card";

import PhoneNumberAssignmentTable from "@/components/PhoneNumberAssignmentTable";
import PhoneNumberAssignmentService from "../services/PhoneNumberAssignmentService";

const tableColumns = [];
const tableData = [
];

export default {
  components: {
    Card,
    PhoneNumberAssignmentTable,
  },
  data() {
    return {
      phoneNumberAssignments: [],
	  totalElements: 0,
      page: 0,
      searchQuery: '',     
      table1: {
        title: "Simple Table",
        columns: [...tableColumns],
        data: [...tableData],
      },
    };
  },
  methods: {
    async getAllPhoneNumberAssignments(sortBy='phoneNumberAssignmentId',sortOrder='true',page="0",size="50") {
      try {
        try {
			const searchDTO = {
				sortBy: sortBy, 
				sortOrder: sortOrder, 
				searchQuery: this.searchQuery,
				page: page, 
				size: size
			};
	        let response = await PhoneNumberAssignmentService.getAllPhoneNumberAssignments(searchDTO);
	        if (!response.data.empty) {
		        if (response.data.phoneNumberAssignments.length) {
					this.phoneNumberAssignments = response.data.phoneNumberAssignments;
				}
      			this.totalElements = response.data.totalElements;
      			this.page = response.data.page;
	        }
        } catch (error) {
          console.error("Error fetching phoneNumberAssignments:", error);
        }
        
      } catch (error) {
        console.error("Error fetching phoneNumberAssignment details:", error);
      }
    },
  },
  mounted() {
    this.getAllPhoneNumberAssignments();
  },
  created() {
    this.$root.$on('searchQueryForPhoneNumberAssignmentsChanged', (searchQuery) => {
    	this.searchQuery = searchQuery;
    	this.getAllPhoneNumberAssignments();
    })
  }
};
</script>
<style></style>


<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card>
          <template slot="header">
            <div class="row justify-content-between align-items-between mx-3">

              <h5 class="card-title">Leads</h5>
              
              <div>
                  <base-button class="btn btn-primary" @click="modalLeads = true">Add</base-button>
	              <base-button class="btn btn-primary" @click="toggleView">Toggle View</base-button>
              </div>
              
              <modal :show.sync="modalLeads">
                <template slot="header">
                  <h5 class="modal-title" id="exampleModalLabel">Add Lead</h5>
                </template>
                <div>
                  <form @submit.prevent>
  <base-input label="WorkEmail" type="text" placeholder="Enter WorkEmail" v-model="leadToAdd.workEmail"></base-input>
  <base-input label="Location" type="text" placeholder="Enter Location" v-model="leadToAdd.location"></base-input>
  <base-input label="JobTitle" type="text" placeholder="Enter JobTitle" v-model="leadToAdd.jobTitle"></base-input>
  <base-input label="Industry" type="text" placeholder="Enter Industry" v-model="leadToAdd.industry"></base-input>
  <base-input label="Company" type="text" placeholder="Enter Company" v-model="leadToAdd.company"></base-input>
  <base-input label="Source" type="text" placeholder="Enter Source" v-model="leadToAdd.source"></base-input>
  <base-input label="Status" type="text" placeholder="Enter Status" v-model="leadToAdd.status"></base-input>
  <base-input label="Notes" type="text" placeholder="Enter Notes" v-model="leadToAdd.notes"></base-input>
  <base-input label="Website" type="text" placeholder="Enter Website" v-model="leadToAdd.website"></base-input>
  <base-input label="MobilePhone" type="text" placeholder="Enter MobilePhone" v-model="leadToAdd.mobilePhone"></base-input>
  <base-input label="PersonalEmails" type="text" placeholder="Enter PersonalEmails" v-model="leadToAdd.personalEmails"></base-input>
  <base-input label="LocationGeo" type="text" placeholder="Enter LocationGeo" v-model="leadToAdd.locationGeo"></base-input>
  <base-input label="LocationLocality" type="text" placeholder="Enter LocationLocality" v-model="leadToAdd.locationLocality"></base-input>
  <base-input label="LocationMetro" type="text" placeholder="Enter LocationMetro" v-model="leadToAdd.locationMetro"></base-input>
  <base-input label="LocationName" type="text" placeholder="Enter LocationName" v-model="leadToAdd.locationName"></base-input>
  <base-input label="LocationNames" type="text" placeholder="Enter LocationNames" v-model="leadToAdd.locationNames"></base-input>
  <base-input label="LocationAddressLine2" type="text" placeholder="Enter LocationAddressLine2" v-model="leadToAdd.locationAddressLine2"></base-input>
  <base-input label="LeadId" type="text" placeholder="Enter LeadId" v-model="leadToAdd.leadId"></base-input>
  <base-input label="Id" type="text" placeholder="Enter Id" v-model="leadToAdd.id"></base-input>
  <base-input label="FullName" type="text" placeholder="Enter FullName" v-model="leadToAdd.fullName"></base-input>
  <base-input label="BirthDate" type="text" placeholder="Enter BirthDate" v-model="leadToAdd.birthDate"></base-input>
  <base-input label="LastModified" type="text" placeholder="Enter LastModified" v-model="leadToAdd.lastModified"></base-input>
  <base-input label="CreatedOn" type="text" placeholder="Enter CreatedOn" v-model="leadToAdd.createdOn"></base-input>
  <base-input label="LastEnriched" type="text" placeholder="Enter LastEnriched" v-model="leadToAdd.lastEnriched"></base-input>
  <base-input label="Revenue" type="text" placeholder="Enter Revenue" v-model="leadToAdd.revenue"></base-input>
  <base-input label="CompanySize" type="text" placeholder="Enter CompanySize" v-model="leadToAdd.companySize"></base-input>
  <base-input label="CompanyDomain" type="text" placeholder="Enter CompanyDomain" v-model="leadToAdd.companyDomain"></base-input>
  <base-input label="Email" type="text" placeholder="Enter Email" v-model="leadToAdd.email"></base-input>
  <base-input label="Phone" type="text" placeholder="Enter Phone" v-model="leadToAdd.phone"></base-input>
  <base-input label="LocationStreetAddress" type="text" placeholder="Enter LocationStreetAddress" v-model="leadToAdd.locationStreetAddress"></base-input>
  <base-input label="LocationRegion" type="text" placeholder="Enter LocationRegion" v-model="leadToAdd.locationRegion"></base-input>
  <base-input label="LocationPostalCode" type="text" placeholder="Enter LocationPostalCode" v-model="leadToAdd.locationPostalCode"></base-input>
  <base-input label="BirthYear" type="text" placeholder="Enter BirthYear" v-model="leadToAdd.birthYear"></base-input>
  <base-input label="LinkedinUrl" type="text" placeholder="Enter LinkedinUrl" v-model="leadToAdd.linkedinUrl"></base-input>
  <base-input label="StreetAddresses" type="text" placeholder="Enter StreetAddresses" v-model="leadToAdd.streetAddresses"></base-input>
  <base-input label="Regions" type="text" placeholder="Enter Regions" v-model="leadToAdd.regions"></base-input>
  <base-input label="RecommendedPersonalEmail" type="text" placeholder="Enter RecommendedPersonalEmail" v-model="leadToAdd.recommendedPersonalEmail"></base-input>
                  </form>
                </div>
                <template slot="footer">
                  <base-button type="primary" @click="handleAddSubmitted()">Save</base-button>
                </template>
              </modal>
            </div>
          </template>
<div>
          <span class="table-title">Leads</span>
          <span>({{this.totalElements}})</span>
          <span><a-pagination
				      :current="pagination.current"
				      :total="pagination.total"
				      :pageSize="pagination.pageSize"
				      @change="onTableChange"
				      show-size-changer
				      :pageSizeOptions="['5','10', '20', '30', '40']"
				      @showSizeChange="handleSizeChange"
				    /></span>
</div>
          <!-- Conditionally render the view based on the 'isTableView' flag -->
          <div v-if="isTableView">
            <!-- Render the existing Table View -->
            <a-table :columns="columns" :data-source="leads" :row-key="record => record.LeadId" :pagination="pagination"
              :loading="searchLoading" @change="onTableChange" :scroll="{ x: 'max-content' }">



	<template slot="birthDate" slot-scope="dataIndex">
       {{ dataIndex ? 'Yes' : 'No' }}
   </template>
	<template slot="birthYear" slot-scope="dataIndex">
       {{ dataIndex ? 'Yes' : 'No' }}
   </template>
	<template slot="locationAddressLine2" slot-scope="dataIndex">
       {{ dataIndex ? 'Yes' : 'No' }}
   </template>
	<template slot="locationGeo" slot-scope="dataIndex">
       {{ dataIndex ? 'Yes' : 'No' }}
   </template>
	<template slot="locationLocality" slot-scope="dataIndex">
       {{ dataIndex ? 'Yes' : 'No' }}
   </template>
	<template slot="locationMetro" slot-scope="dataIndex">
       {{ dataIndex ? 'Yes' : 'No' }}
   </template>
	<template slot="locationName" slot-scope="dataIndex">
       {{ dataIndex ? 'Yes' : 'No' }}
   </template>
	<template slot="locationNames" slot-scope="dataIndex">
       {{ dataIndex ? 'Yes' : 'No' }}
   </template>
	<template slot="locationPostalCode" slot-scope="dataIndex">
       {{ dataIndex ? 'Yes' : 'No' }}
   </template>
	<template slot="locationRegion" slot-scope="dataIndex">
       {{ dataIndex ? 'Yes' : 'No' }}
   </template>
	<template slot="locationStreetAddress" slot-scope="dataIndex">
       {{ dataIndex ? 'Yes' : 'No' }}
   </template>
	<template slot="mobilePhone" slot-scope="dataIndex">
       {{ dataIndex ? 'Yes' : 'No' }}
   </template>
	<template slot="personalEmails" slot-scope="dataIndex">
       {{ dataIndex ? 'Yes' : 'No' }}
   </template>
	<template slot="recommendedPersonalEmail" slot-scope="dataIndex">
       {{ dataIndex ? 'Yes' : 'No' }}
   </template>
	<template slot="regions" slot-scope="dataIndex">
       {{ dataIndex ? 'Yes' : 'No' }}
   </template>
	<template slot="streetAddresses" slot-scope="dataIndex">
       {{ dataIndex ? 'Yes' : 'No' }}
   </template>
	<template slot="workEmail" slot-scope="dataIndex">
       {{ dataIndex ? 'Yes' : 'No' }}
   </template>

             <template slot="lastModified" slot-scope="dataIndex">
              	{{ formatTime(dataIndex) }}
              </template>
              <template slot="createdOn" slot-scope="dataIndex">
              	{{ formatTime(dataIndex) }}
              </template>
              <template slot="endedOn" slot-scope="dataIndex">
              	{{ formatTime(dataIndex) }}
              </template>
              <template slot="blackOutStartDate" slot-scope="dataIndex">
              	{{ formatDate(dataIndex) }}
              </template>
            </a-table>
          </div>
          <div v-else>
            <!-- Render the Picture View  -->
            <LeadPictureView :leads="leads" />
          </div>

        </card>
      </div>
    </div>

  </div>
</template>

<script>
import Modal from "@/components/Modal";
import BaseButton from "@/components/BaseButton";
import BaseInput from "@/components/BaseInput";
import NotificationTemplate from "@/pages/Notifications/NotificationTemplate";
import { Card } from "@/components/Card";
import LeadService from "../services/LeadService";
import { ASelect, ASelectOption, AButton, Table, Pagination } from "ant-design-vue";
import LeadPictureView from './LeadPictureView.vue';


const leadsColumns = [
  "leadId",
  "leadId"
]

export default {
  props: {
    leads: {
      type: Array,
      required: true,
    },
    totalElements: {
      type: Number,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
  },
  components: {
    Card,
    Modal,
    BaseButton,
    BaseInput,
    Table,

    Pagination,
    LeadPictureView
  },

  data() {
    return {
      modalLeads: false,
        isTableView: false,

      columns: [
        {
          title: 'Lead Id',
		dataIndex: 'leadId',
          visible: true,
          scopedSlots: { customRender: 'leadId' },
          sorter: true
          //sorter: (a, b) => a.leadId - b.leadId,
          //sorter: (a, b) => a.leadId.localeCompare(b.leadId),
        },
        {
          title: 'Id',
		dataIndex: 'id',
          visible: true,
          scopedSlots: { customRender: 'id' },
          sorter: true
          //sorter: (a, b) => a.id - b.id,
          //sorter: (a, b) => a.id.localeCompare(b.id),
        },
        {
          title: 'Full Name',
		dataIndex: 'fullName',
          visible: true,
          scopedSlots: { customRender: 'fullName' },
          sorter: true
          //sorter: (a, b) => a.fullName - b.fullName,
          //sorter: (a, b) => a.fullName.localeCompare(b.fullName),
        },
        {
          title: 'Birth Date',
		dataIndex: 'birthDate',
          visible: true,
          scopedSlots: { customRender: 'birthDate' },
          sorter: true
          //sorter: (a, b) => a.birthDate - b.birthDate,
          //sorter: (a, b) => a.birthDate.localeCompare(b.birthDate),
        },
        {
          title: 'Birth Year',
		dataIndex: 'birthYear',
          visible: true,
          scopedSlots: { customRender: 'birthYear' },
          sorter: true
          //sorter: (a, b) => a.birthYear - b.birthYear,
          //sorter: (a, b) => a.birthYear.localeCompare(b.birthYear),
        },
        {
          title: 'Location Address Line2',
		dataIndex: 'locationAddressLine2',
          visible: true,
          scopedSlots: { customRender: 'locationAddressLine2' },
          sorter: true
          //sorter: (a, b) => a.locationAddressLine2 - b.locationAddressLine2,
          //sorter: (a, b) => a.locationAddressLine2.localeCompare(b.locationAddressLine2),
        },
        {
          title: 'Location Geo',
		dataIndex: 'locationGeo',
          visible: true,
          scopedSlots: { customRender: 'locationGeo' },
          sorter: true
          //sorter: (a, b) => a.locationGeo - b.locationGeo,
          //sorter: (a, b) => a.locationGeo.localeCompare(b.locationGeo),
        },
        {
          title: 'Location Locality',
		dataIndex: 'locationLocality',
          visible: true,
          scopedSlots: { customRender: 'locationLocality' },
          sorter: true
          //sorter: (a, b) => a.locationLocality - b.locationLocality,
          //sorter: (a, b) => a.locationLocality.localeCompare(b.locationLocality),
        },
        {
          title: 'Location Metro',
		dataIndex: 'locationMetro',
          visible: true,
          scopedSlots: { customRender: 'locationMetro' },
          sorter: true
          //sorter: (a, b) => a.locationMetro - b.locationMetro,
          //sorter: (a, b) => a.locationMetro.localeCompare(b.locationMetro),
        },
        {
          title: 'Location Name',
		dataIndex: 'locationName',
          visible: true,
          scopedSlots: { customRender: 'locationName' },
          sorter: true
          //sorter: (a, b) => a.locationName - b.locationName,
          //sorter: (a, b) => a.locationName.localeCompare(b.locationName),
        },
        {
          title: 'Location Names',
		dataIndex: 'locationNames',
          visible: true,
          scopedSlots: { customRender: 'locationNames' },
          sorter: true
          //sorter: (a, b) => a.locationNames - b.locationNames,
          //sorter: (a, b) => a.locationNames.localeCompare(b.locationNames),
        },
        {
          title: 'Location Postal Code',
		dataIndex: 'locationPostalCode',
          visible: true,
          scopedSlots: { customRender: 'locationPostalCode' },
          sorter: true
          //sorter: (a, b) => a.locationPostalCode - b.locationPostalCode,
          //sorter: (a, b) => a.locationPostalCode.localeCompare(b.locationPostalCode),
        },
        {
          title: 'Location Region',
		dataIndex: 'locationRegion',
          visible: true,
          scopedSlots: { customRender: 'locationRegion' },
          sorter: true
          //sorter: (a, b) => a.locationRegion - b.locationRegion,
          //sorter: (a, b) => a.locationRegion.localeCompare(b.locationRegion),
        },
        {
          title: 'Location Street Address',
		dataIndex: 'locationStreetAddress',
          visible: true,
          scopedSlots: { customRender: 'locationStreetAddress' },
          sorter: true
          //sorter: (a, b) => a.locationStreetAddress - b.locationStreetAddress,
          //sorter: (a, b) => a.locationStreetAddress.localeCompare(b.locationStreetAddress),
        },
        {
          title: 'Mobile Phone',
		dataIndex: 'mobilePhone',
          visible: true,
          scopedSlots: { customRender: 'mobilePhone' },
          sorter: true
          //sorter: (a, b) => a.mobilePhone - b.mobilePhone,
          //sorter: (a, b) => a.mobilePhone.localeCompare(b.mobilePhone),
        },
        {
          title: 'Personal Emails',
		dataIndex: 'personalEmails',
          visible: true,
          scopedSlots: { customRender: 'personalEmails' },
          sorter: true
          //sorter: (a, b) => a.personalEmails - b.personalEmails,
          //sorter: (a, b) => a.personalEmails.localeCompare(b.personalEmails),
        },
        {
          title: 'Recommended Personal Email',
		dataIndex: 'recommendedPersonalEmail',
          visible: true,
          scopedSlots: { customRender: 'recommendedPersonalEmail' },
          sorter: true
          //sorter: (a, b) => a.recommendedPersonalEmail - b.recommendedPersonalEmail,
          //sorter: (a, b) => a.recommendedPersonalEmail.localeCompare(b.recommendedPersonalEmail),
        },
        {
          title: 'Regions',
		dataIndex: 'regions',
          visible: true,
          scopedSlots: { customRender: 'regions' },
          sorter: true
          //sorter: (a, b) => a.regions - b.regions,
          //sorter: (a, b) => a.regions.localeCompare(b.regions),
        },
        {
          title: 'Street Addresses',
		dataIndex: 'streetAddresses',
          visible: true,
          scopedSlots: { customRender: 'streetAddresses' },
          sorter: true
          //sorter: (a, b) => a.streetAddresses - b.streetAddresses,
          //sorter: (a, b) => a.streetAddresses.localeCompare(b.streetAddresses),
        },
        {
          title: 'Work Email',
		dataIndex: 'workEmail',
          visible: true,
          scopedSlots: { customRender: 'workEmail' },
          sorter: true
          //sorter: (a, b) => a.workEmail - b.workEmail,
          //sorter: (a, b) => a.workEmail.localeCompare(b.workEmail),
        },
        {
          title: 'Location',
		dataIndex: 'location',
          visible: true,
          scopedSlots: { customRender: 'location' },
          sorter: true
          //sorter: (a, b) => a.location - b.location,
          //sorter: (a, b) => a.location.localeCompare(b.location),
        },
        {
          title: 'Job Title',
		dataIndex: 'jobTitle',
          visible: true,
          scopedSlots: { customRender: 'jobTitle' },
          sorter: true
          //sorter: (a, b) => a.jobTitle - b.jobTitle,
          //sorter: (a, b) => a.jobTitle.localeCompare(b.jobTitle),
        },
        {
          title: 'Industry',
		dataIndex: 'industry',
          visible: true,
          scopedSlots: { customRender: 'industry' },
          sorter: true
          //sorter: (a, b) => a.industry - b.industry,
          //sorter: (a, b) => a.industry.localeCompare(b.industry),
        },
        {
          title: 'Company',
		dataIndex: 'company',
          visible: true,
          scopedSlots: { customRender: 'company' },
          sorter: true
          //sorter: (a, b) => a.company - b.company,
          //sorter: (a, b) => a.company.localeCompare(b.company),
        },
        {
          title: 'Website',
		dataIndex: 'website',
          visible: true,
          scopedSlots: { customRender: 'website' },
          sorter: true
          //sorter: (a, b) => a.website - b.website,
          //sorter: (a, b) => a.website.localeCompare(b.website),
        },
        {
          title: 'Phone',
		dataIndex: 'phone',
          visible: true,
          scopedSlots: { customRender: 'phone' },
          sorter: true
          //sorter: (a, b) => a.phone - b.phone,
          //sorter: (a, b) => a.phone.localeCompare(b.phone),
        },
        {
          title: 'Email',
		dataIndex: 'email',
          visible: true,
          scopedSlots: { customRender: 'email' },
          sorter: true
          //sorter: (a, b) => a.email - b.email,
          //sorter: (a, b) => a.email.localeCompare(b.email),
        },
        {
          title: 'Company Domain',
		dataIndex: 'companyDomain',
          visible: true,
          scopedSlots: { customRender: 'companyDomain' },
          sorter: true
          //sorter: (a, b) => a.companyDomain - b.companyDomain,
          //sorter: (a, b) => a.companyDomain.localeCompare(b.companyDomain),
        },
        {
          title: 'Company Size',
		dataIndex: 'companySize',
          visible: true,
          scopedSlots: { customRender: 'companySize' },
          sorter: true
          //sorter: (a, b) => a.companySize - b.companySize,
          //sorter: (a, b) => a.companySize.localeCompare(b.companySize),
        },
        {
          title: 'Linkedin Url',
		dataIndex: 'linkedinUrl',
          visible: true,
          scopedSlots: { customRender: 'linkedinUrl' },
          sorter: true
          //sorter: (a, b) => a.linkedinUrl - b.linkedinUrl,
          //sorter: (a, b) => a.linkedinUrl.localeCompare(b.linkedinUrl),
        },
        {
          title: 'Revenue',
		dataIndex: 'revenue',
          visible: true,
          scopedSlots: { customRender: 'revenue' },
          sorter: true
          //sorter: (a, b) => a.revenue - b.revenue,
          //sorter: (a, b) => a.revenue.localeCompare(b.revenue),
        },
        {
          title: 'Last Enriched',
		dataIndex: 'lastEnriched',
          visible: true,
          scopedSlots: { customRender: 'lastEnriched' },
          sorter: true
          //sorter: (a, b) => a.lastEnriched - b.lastEnriched,
          //sorter: (a, b) => a.lastEnriched.localeCompare(b.lastEnriched),
        },
        {
          title: 'Notes',
		dataIndex: 'notes',
          visible: true,
          scopedSlots: { customRender: 'notes' },
          sorter: true
          //sorter: (a, b) => a.notes - b.notes,
          //sorter: (a, b) => a.notes.localeCompare(b.notes),
        },
        {
          title: 'Status',
		dataIndex: 'status',
          visible: true,
          scopedSlots: { customRender: 'status' },
          sorter: true
          //sorter: (a, b) => a.status - b.status,
          //sorter: (a, b) => a.status.localeCompare(b.status),
        },
        {
          title: 'Source',
		dataIndex: 'source',
          visible: true,
          scopedSlots: { customRender: 'source' },
          sorter: true
          //sorter: (a, b) => a.source - b.source,
          //sorter: (a, b) => a.source.localeCompare(b.source),
        },
        {
          title: 'Created On',
		dataIndex: 'createdOn',
          visible: true,
          scopedSlots: { customRender: 'createdOn' },
          sorter: true
          //sorter: (a, b) => a.createdOn - b.createdOn,
          //sorter: (a, b) => a.createdOn.localeCompare(b.createdOn),
        },
        {
          title: 'Last Modified',
		dataIndex: 'lastModified',
          visible: true,
          scopedSlots: { customRender: 'lastModified' },
          sorter: true
          //sorter: (a, b) => a.lastModified - b.lastModified,
          //sorter: (a, b) => a.lastModified.localeCompare(b.lastModified),
        },
      ],
      pagination: {
        current: 1,
        pageSize: 50,
        total: 0,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total) => `Total ${total} leads`,
      },

      leads: [],
      leadToAdd: {},

      leadsTable: {
        columns: [...leadsColumns],
        data: [],
      },

      // New properties for sorting and searching
      sortBy: 'leadId',           // Column to sort by
      sortOrder: 'asc',     // Sort order (asc or desc)
      searchLoading: false, // Initialize searchLoading property


    };
  },

  methods: {

    async renderLeadsTable() {
      this.searchLoading = true; // Set searchLoading to true while fetching data
      this.searchLoading = false;

      this.pagination.total = this.totalElements;
      this.pagination.current = this.page;

      let leadsTableData = [];
      for (let i = 0; i < this.leads.length; i++) {
        leadsTableData.push({
          id: i,
          leadId: this.leads[i].leadId,
          leadId: this.leads[i].leadId,
        });

      }
      this.searchLoading = false;
    },

    async onTableChange(pagination, filters, sorter) {
      if (sorter && sorter.field && sorter.order) {
        this.sortBy = sorter.field;
        if (sorter.order == "ascend") {
            this.sortOrder = "asc";
        } else {
            this.sortOrder = "desc";
        }
      }
      if (pagination) {
        this.pagination.current = pagination.current;
        this.pagination.pageSize = pagination.pageSize;
      }

	  this.$emit('get-all-leads',this.sortBy,this.sortOrder,this.pagination.current-1,this.pagination.pageSize);
      this.handleTableChanged()
    },
	
	initializeColumns() {
        this.columns = this.columns.filter(item => item.visible);
    },

    routingToPhoneNumberDetail(id) {
      this.$router.push({ name: 'PhoneNumberDetail', params: { phoneNumberId: id.toString() }})
    },
    routingToPhoneNumberAssignmentDetail(id) {
      this.$router.push({ name: 'PhoneNumberAssignmentDetail', params: { phoneNumberAssignmentId: id.toString() }})
    },
    routingToLeadListDetail(id) {
      this.$router.push({ name: 'LeadListDetail', params: { leadListId: id.toString() }})
    },
    routingToActivityLogDetail(id) {
      this.$router.push({ name: 'ActivityLogDetail', params: { activityLogId: id.toString() }})
    },
    routingToCarrierDetail(id) {
      this.$router.push({ name: 'CarrierDetail', params: { carrierId: id.toString() }})
    },
    routingToLeadDetail(id) {
      this.$router.push({ name: 'LeadDetail', params: { leadId: id.toString() }})
    },
    routingToCallDetail(id) {
      this.$router.push({ name: 'CallDetail', params: { callId: id.toString() }})
    },
    
    toggleView() {
      this.isTableView = !this.isTableView;
    },
	
	async handleAddSubmitted() {
      this.modalLeads = false;

      const currentDate = new Date().getTime();
      this.leadToAdd.created = currentDate;

      const jsonData = JSON.stringify(this.leadToAdd);
      console.log(jsonData);
      
      const res = await LeadService.addLead(jsonData);

      if (res.status === 200) {
        this.$notify({
          component: NotificationTemplate,
          icon: "tim-icons icon-bell-55",
          type: "success",
          timeout: 3000,
        });
      }
	},
	
	handleTableChanged() {
	},
	
	formatTime(dateString) {
      if(dateString !== null){
        const date = new Date(dateString);
      return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')} EST`;
      }
      // Format the date here as needed, for example:
    },  
    
 formatDate(dateString) {
    if (dateString !== null) {
	    const date = new Date(dateString);
	    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	    const day = String(date.getDate()).padStart(2, '0');
	    const monthAbbreviation = months[date.getMonth()];
	    const year = date.getFullYear();
	    return `${day} ${monthAbbreviation} ${year}`;
  	}
  	// Handle the case when dateString is null or invalid
  	return '';
    
   },

  },
  mounted() {
  	this.initializeColumns();
    this.renderLeadsTable();
  }
};
</script>

<style>
.modal-dialog {
  margin-top: -300px;
}
.ant-table-row  {
	text-align: center;
}
.ant-table-thead th span {
	text-align: center;
	width: 100%
}
.ant-table-fixed td span {
    text-align: center;
}

.table-title {
	font-size: 1.75rem;
	font-weight: bold;
	font-style: italic;
	color: #11ba81;
	text-align: left;
	padding: 0px 15px;
}

.lead-link {
	color: #11ba81;
}

</style>

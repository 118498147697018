import http from "../http-common"; 

class CallService {
  getAllCalls(searchDTO) {
    console.log(searchDTO)
    return this.getRequest(`/call/calls`, searchDTO);
  }
 

  get(callId) {
    return this.getRequest(`/call/${callId}`, null);
  }

  findByField(matchData) {
    return this.getRequest(`/call?field=${matchData}`, null);
  }

  addCall(data) {
    return http.post("/call/addCall", data);
  }

  update(data) {
  	return http.post("/call/updateCall", data);
  }
  
  uploadImage(data,callId) {
  	return http.postForm("/call/uploadImage/"+callId, data);
  }




	postRequest(url, data) {
        const token = localStorage.getItem('authToken');
        if (token) {
            return http.post(url, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
        }
      };

	getRequest(url, params) {
        const token = localStorage.getItem('authToken');
        if (token) {
            return http.get(url, {
            	params: params,
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
        }
    };

}

export default new CallService();

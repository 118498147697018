<template>
  <div class="main-container">
    <!-- Header -->
    <div class="app-header-container">
      <app-header />
    </div>

    <!-- Main layout with fixed sidebar and content -->
    <div class="main-layout">
      <!-- Fixed Sider with Controlled Open Keys for Nested Menu, 100px from the top -->
      <div class="sidebar">
        <a-menu
          mode="inline"
          :openKeys="openKeys"
          @openChange="onOpenChange"
          style="height: 100%; border-right: 0"
        >
          <a-menu-item>
            <router-link to="/dialer">
              <font-awesome-icon :icon="['fas', 'phone']" />
              <span class="label">Dialer</span>
            </router-link>
          </a-menu-item>

          <a-menu-item>
            <router-link to="/leads">
              <font-awesome-icon :icon="['fas', 'clipboard-list']" />
              <span class="label">Leads</span>
            </router-link>
          </a-menu-item>

          <a-menu-item>
            <router-link to="/calls">
              <font-awesome-icon :icon="['fas', 'list']" />
              <span class="label">Calls</span>
            </router-link>
          </a-menu-item>

          <a-menu-item>
            <router-link to="/phoneNumbers">
              <font-awesome-icon :icon="['fas', 'list']" />
              <span class="label">Phone Numbers</span>
            </router-link>
          </a-menu-item>

          <a-menu-item>
            <router-link to="/phoneNumberAssignments">
              <font-awesome-icon :icon="['fas', 'clipboard']" />
              <span class="label"># Assignments</span>
            </router-link>
          </a-menu-item>

          <a-menu-item>
            <router-link to="/leadLists">
              <font-awesome-icon :icon="['fas', 'comments']" />
              <span class="label">Lead Lists</span>
            </router-link>
          </a-menu-item>

          <a-menu-item>
            <router-link to="/activityLogs">
              <font-awesome-icon :icon="['fas', 'users']" />
              <span class="label">Activity Logs</span>
            </router-link>
          </a-menu-item>

          <a-menu-item>
            <router-link to="/users">
              <font-awesome-icon :icon="['fas', 'list']" />
              <span class="label">Users</span>
            </router-link>
          </a-menu-item>
        </a-menu>
      </div>
      
      <!-- Content area (offset sidebar space, moved down by 100px from header) -->
      <div class="content-area">

        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import AppHeader from "@/components/AppHeader.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { 
  faUsers, 
  faPhone, 
  faList, 
  faClipboard, 
  faComments, 
  faClipboardList 
} from "@fortawesome/free-solid-svg-icons";

library.add(faUsers, faPhone, faList, faClipboard, faComments, faClipboardList);

export default {
  name: "DefaultLayout",
  components: {
    AppHeader,
    FontAwesomeIcon, // Register FontAwesome
  },
  setup() {
    const openKeys = ref(["sub1"]); // default open submenu
    const onOpenChange = (keys) => {
      // Open only the last clicked submenu, for single open behavior
      openKeys.value = keys.length ? [keys[keys.length - 1]] : [];
    };

    return {
      openKeys,
      onOpenChange,
    };
  },
};
</script>

<style scoped>

.header-search {
  width: 100%; /* Ensures full-width styling for input */
}

/* Main content and layout styles */
.main-container {
  height: 100%;
  min-height: 100vh;
}

.app-header-container {
  background: #fff;
  padding: 0;
}

.main-layout {
  display: flex;
  height: 100vh;
}

.sidebar {
  position: fixed;
  top: 100px;
  bottom: 0;
  height: calc(100vh - 100px);
  width: 200px;
  background: #fff;
  overflow-y: auto;
}

.content-area {
  margin-left: 200px;
  padding: 16px;
  margin-top: 100px; /* Push content down, aligning with header height */
}

/* Menu styles */
.label {
  margin-left: 15px;
  font-size: medium;
}

.ant-menu-item {
  text-align: left;
}

.ant-layout-sider {
  position: fixed;  /* Keeps sidebar fixed */
  top: 100px;       /* Keeps sidebar 100px from the top */
  bottom: 0;        /* Ensures it stretches to the bottom */
  height: calc(100vh - 100px);
  overflow-y: auto;
}
</style>


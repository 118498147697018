<template>
  <div class="content">

    <div class="dashboard-title">Auto Dialer </div>

	  <div id="app" class="container mt-5">
	    <div class="row">
	      <div class="col-md-6 offset-md-3">
<br>
	              <button class="btn btn-reachit btn-start-auto-dialing" @click="startAutoDialing">
	                Start Auto Dialing
	              </button>
  <br>
  <br>
	        <div class="card">
	          <div class="card-body">

<!--
	            <div v-if="!autoDialerEnabled" class="start-dialing">
	              <button class="btn btn-reachit btn-start-auto-dialing" @click="startDialing">
	                Start Auto Dialing - simulation
	              </button>
	            </div>
	            
	            <div v-if="autoDialerEnabled" class="start-dialing">
	              <button class="btn btn-reachit btn-stop-auto-dialing" @click="stopDialing">
	                || Pause Auto Dialing
	              </button>
	            </div>
-->

<div v-if="!connectedLead" class="current-dialing mb-3">
  <h3 v-if="isDialing">Dialing In Progress</h3>
  <ul class="list-group">
<li 
  v-for="number in currentNumbers" 
  :key="number" 
  class="list-group-item d-flex align-items-center"
  v-show="dialingStatus[number]?.status === 'dialing'">

  <!-- Status Badge and Phone Number -->
  <div class="status-badge ms-3 d-flex align-items-center">
    <span class="badge bg-warning">
      {{ dialingStatus[number]?.status || "Dialing" }}
    </span>
    <span class="dialer-lead-phone ms-2">{{ number }}</span>
  </div>

  <!-- Lead Name & Title -->
  <div class="dialer-lead-info d-flex align-items-center flex-grow-1">
    <strong class="dialer-lead-name">{{ getLeadName(number) }}</strong>
    <strong class="dialer-lead-name">{{ getLeadTitle(number) }}</strong>
    <strong class="dialer-lead-name">{{ getLeadCompany(number) }}</strong>
  </div>
  
  <!-- Progress Bar -->
  <div class="progress-container me-3">
    <div 
      class="progress-bar" 
      role="progressbar" 
      :style="{ width: dialingStatus[number]?.progress + '%' }"
    ></div>
  </div>

  <!-- End Call Button -->
  <div class="dialing-buttons ms-3">
    <button class="btn btn-reachit btn-end-call">End</button>
  </div>
</li>
  </ul>


  <ul class="list-group">
<li 
  v-for="call in callsDialed" 
  :key="callId" 
  class="list-group-item d-flex align-items-center">

  <div class="status-badge ms-3 d-flex align-items-center">
    <span class="badge bg-warning">
      {{ call.status }}
    </span>
    <span class="dialer-lead-phone ms-2">{{ call.status }}</span>
  </div>

  <!-- Lead Name & Title -->
  <div class="dialer-lead-info d-flex align-items-center flex-grow-1">
    <strong class="dialer-lead-name">{{ call.leadName }}</strong>
    <strong class="dialer-lead-name">{{ call.leadTitle }}</strong>
    <strong class="dialer-lead-name">{{ call.leadCompany }}</strong>
  </div>
  
  <!-- Progress Bar -->
  <div class="progress-container me-3">
    <div 
      class="progress-bar" 
      role="progressbar" 
      :style="{ width: dialingStatus[number]?.progress + '%' }"
    ></div>
  </div>

  <!-- End Call Button -->
  <div class="dialing-buttons ms-3">
    <button class="btn btn-reachit btn-end-call">End</button>
  </div>
</li>
  </ul>
</div>


	              
	              <div v-if="connectedLead" class="connected-lead">
	                <h3>Call Connected</h3>
	                <div class="card">
	                  <div class="card-body">
	                    <p><strong>Name:</strong> {{ connectedLead.name }}</p>
	                    <p><strong>Company:</strong> {{ connectedLead.company }}</p>
	                    <p><strong>Title:</strong> {{ connectedLead.title }}</p>
	                    <p><strong>Email:</strong> {{ connectedLead.email }}</p>
	                    <p><strong>Phone:</strong> {{ connectedLead.phone }}</p>
	                  </div>
	                </div>

	                <button class="btn btn-reachit btn-end-call" @click="endCall">
	                  <font-awesome-icon :icon="['fas', 'phone']" />End
	                </button>
	              </div>
	              
	          </div>
	        </div>
	      </div>
	    </div>
	  </div>

  </div>
</template>

<script>

import LeadService from "../services/LeadService";
import BaseButton from "@/components/BaseButton";
import NotificationTemplate from "@/pages/Notifications/NotificationTemplate";
import { Card } from "@/components/Card";
import { ASelect, ASelectOption, AButton, Table, Pagination, message } from "ant-design-vue";
import { ref, reactive } from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { 
  faUsers, 
  faPhone, 
  faList, 
  faClipboard, 
  faComments, 
  faClipboardList 
} from "@fortawesome/free-solid-svg-icons";

library.add(faUsers, faPhone, faList, faClipboard, faComments, faClipboardList);


let device;
let currentCall = null;
let twilioToken = '';
let activeCallSid;


let file = ({});
const checked = (false);
let yearValue = ('');

export default {
  components: {
    BaseButton,
    Card,
    FontAwesomeIcon,
  },
  data() {
    return {
      autoDialerEnabled: false,
      isDialing: false,
      dialProgress: 0,
      currentNumbers: [],
      callsDialed: [],
      dialingStatus: reactive({}),
      connectedLead: null,
      leadDetails: null,
      checked,
	  yearValue,
    };
  },
   props: {
    leadId: {
      type: String,
      required: true
    }
  },
  methods: {

	startDialing() {
	  this.autoDialerEnabled = true;
      this.isDialing = true;
      this.prepareNextBatch();
    },




	getCallsDialedForAgent() {
	
		const token = localStorage.getItem('authToken');
	
	    const fetchCalls = () => {
	        // Make the API call here
	        fetch('https://60secondapps-0006.vip:8080/call/callsDialedForAgent', {
	            method: 'GET', 
			      headers: {
					Authorization: `Bearer ${token}`,
			        "Accept": "application/json",
			        "Content-Type": "application/json",
			      },
      			  credentials: "omit",
	        })
	        .then(response => response.json())
	        .then(data => {
	            console.log('Calls dialed for agent:', data);
	            this.callsDialed = data.calls;
	        })
	        .catch(error => {
	            console.error('Error fetching calls:', error);
	        })
	        .finally(() => {
	            // Call the function again after 3 seconds
	            setTimeout(fetchCalls, 30000);
	        });
	    };
	
	    fetchCalls(); // Initial call
	},
    
simulateDial() {
  if (!this.autoDialerEnabled) return; // Stop dialing if disabled

  let dialAttempts = 0;
  const totalAttempts = this.currentNumbers.length;
  const minimumDialTime = 10000; // 10 seconds

  this.dialingIntervals = {}; // Reset intervals before dialing starts

  // ✅ Initialize dialingStatus properly
  this.dialingStatus = this.currentNumbers.reduce((acc, number) => {
    acc[number] = { status: "dialing", progress: 0 };  // ✅ Always ensure this is an object
    return acc;
  }, {});

  this.currentNumbers.forEach((number) => {
    setTimeout(() => {
      if (!this.autoDialerEnabled) return; // Stop processing if dialing is disabled

      const randomOutcome = Math.random();

      // ✅ Ensure dialingStatus[number] exists as an object
      if (!this.dialingStatus[number] || typeof this.dialingStatus[number] !== 'object') {
        this.dialingStatus[number] = { status: "dialing", progress: 0 };
      }

      if (randomOutcome < 0.4 && !this.connectedLead) {
        setTimeout(() => {
          if (!this.connectedLead) {
            this.dialingStatus[number].status = "answered";
//TEB 2-2 10:35pm            this.connectedLead = this.leads.find((l) => l.phone === number);
          }
        }, minimumDialTime);
      } else {
        this.dialingStatus[number].status = "failed";
      }

      // Simulate progress independently
      const totalProgressTime = minimumDialTime;
      const progressIntervalTime = 200;
      const progressPerInterval = 100 / (totalProgressTime / progressIntervalTime);

      const interval = setInterval(() => {
        if (!this.autoDialerEnabled || !this.dialingStatus[number]) {
          clearInterval(interval);
          return;
        }

        this.dialingStatus[number].progress += progressPerInterval;

        if (this.dialingStatus[number].progress >= 100) {
          this.dialingStatus[number].progress = 100;
          clearInterval(interval);
        }
      }, progressIntervalTime);

      this.dialingIntervals[number] = interval;

      dialAttempts++;
      this.dialProgress = (dialAttempts / totalAttempts) * 100;

      if (dialAttempts === totalAttempts && !this.connectedLead) {
        this.prepareNextBatch();
      }
    }, Math.random() * 3000 + 1000);
  });
},

    prepareNextBatch() {
      const usedLeads = new Set(this.currentNumbers);
      const remainingLeads = this.leads.filter((lead) => !usedLeads.has(lead.phone));

      if (remainingLeads.length > 0 || this.currentNumbers.length < 5) {
        while (this.currentNumbers.length < 5 && remainingLeads.length) {
          const nextLead = remainingLeads.shift();
          if (!this.currentNumbers.includes(nextLead.phone)) {
            this.currentNumbers.push(nextLead.phone);
          }
        }

        this.currentNumbers.forEach((number) => {
          this.dialingStatus[number] = "dialing";
        });

        this.simulateDial();
      } else {
        this.stopDialing();
      }
    },

stopDialing() {
  this.autoDialerEnabled = false;
  this.isDialing = false;
  this.dialProgress = 0;

  // Clear all active progress intervals
  if (this.dialingIntervals) {
    Object.values(this.dialingIntervals).forEach((interval) => clearInterval(interval));
  }
  this.dialingIntervals = {}; // Reset interval tracking

  // ✅ Ensure dialingStatus[number] is an object before modifying it
  if (this.dialingStatus) {
    Object.keys(this.dialingStatus).forEach((key) => {
      if (typeof this.dialingStatus[key] === 'object') {  // ✅ Prevents overwriting with a string
        this.dialingStatus[key].progress = 0;
        this.dialingStatus[key].status = "idle"; // Changed to "idle" to avoid confusion
      }
    });
  }
},
    endCall() {
	    console.log("Call disconnected. Cleaning up.");
	    this.connectedLead = null;
	    activeCallSid = null;
	    device.destroy();
	    device = null; // Reset device to allow a new initialization
    },
    
 	handlePermissionDialogClose() {
      this.userPermissionDialog = false;
    },

    getLeadName(phoneNumber) {
      const lead = this.leads.find(l => l.phone === phoneNumber);
      return lead ? lead.name : "Unknown";
    },
  
    getLeadTitle(phoneNumber) {
      const lead = this.leads.find(l => l.phone === phoneNumber);
      return lead ? lead.title : "Unknown";
    },

    getLeadCompany(phoneNumber) {
      const lead = this.leads.find(l => l.phone === phoneNumber);
      return lead ? lead.company : "Unknown";
    },

    async getLeadDetails() {
      try {
			let response = await LeadService.get(this.leadId);
			this.leadDetails = response.data;

			let hasAnswers = 0;

			if (hasAnswers) {			
		        this.answers.forEach(function(answer) {
		            if (answer.answer === "Yes") {
		               temp = true;
		            } else {
		               temp = false;
		            }
		        });
			}

	    
      } catch (error) {
        console.error('Error fetching lead details:', error);
      }
    },

	formatTime(dateString) {
      if(dateString !== null){
        const date = new Date(dateString);
      return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')} EST`;
      }
      // Format the date here as needed, for example:
    },  
    
 formatDate(dateString) {
    if (dateString !== null) {
	    const date = new Date(dateString);
	    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	    const day = String(date.getDate()).padStart(2, '0');
	    const monthAbbreviation = months[date.getMonth()];
	    const year = date.getFullYear();
	    return `${day} ${monthAbbreviation} ${year}`;
  	}
  	// Handle the case when dateString is null or invalid
  	return '';
    
   },



showError(message) {
  const errorDiv = document.getElementById('errorMessage');
  errorDiv.textContent = message;
  errorDiv.style.display = 'block';
  setTimeout(() => {
    errorDiv.style.display = 'none';
  }, 5000);
},



async initializeTwilio() {
  try {
    // Destroy any existing Twilio device before initializing a new one
    if (device) {
      console.log('Destroying existing Twilio device before reinitialization.');
      device.destroy();
      device = null; // Ensure it's fully reset
    }

    // Fetch a new Twilio token
    const response = await fetch('https://60secondapps-0006.vip:8080/api/calls/token', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'omit'
    });

    if (!response.ok) {
      console.error('Response status:', response.status);
      console.error('Response headers:', [...response.headers.entries()]);
      const text = await response.text();
      console.error('Response body:', text);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const contentType = response.headers.get('content-type');
    if (!contentType || !contentType.includes('application/json')) {
      throw new Error(`Expected JSON response but got ${contentType}`);
    }

    twilioToken = await response.text();
    console.log('Received token:', twilioToken);

    // Decode token to check expiration
    const decodedToken = jwt_decode(twilioToken);
    const currentTime = Math.floor(Date.now() / 1000);
    if (decodedToken.exp < currentTime) {
      console.error("Twilio token expired, requesting a new one.");
      return await this.initializeTwilio();
    }

    // Initialize Twilio Device
    device = new Twilio.Device(twilioToken, {
      codecPreferences: ['opus', 'pcmu'],
      fakeLocalDTMF: true,
      enableRingingState: true,
      region: 'us1'
    });

    device.register();

    // Handle incoming calls
    device.on('incoming', (call) => {
      console.log("Incoming call:", call.parameters.CallSid);

      if (activeCallSid) {
        console.log("Already on a call, rejecting incoming call.");
        call.reject();
        return;
      }

      activeCallSid = call.parameters.CallSid;
      console.log("Accepted new call:", activeCallSid);

      const browserLegCallSid = activeCallSid;
      const originalCallSid = call.customParameters.get("originalCallSid");

      this.updateBrowserLegCallSid(browserLegCallSid, originalCallSid);
      
      this.getLeadDetailsForConnectedCall(originalCallSid);
      
      call.accept();
      this.connectedLead = "TEST";
      this.stopDialing();

      // Cleanup when call ends
      call.on('disconnect', () => {
        console.log("Call disconnected. Cleaning up.");
        this.connectedLead = null;
        activeCallSid = null;
        device.destroy();
        device = null; // Reset device to allow a new initialization
      });
    });

    // Handle manual disconnects
    device.on('disconnect', () => {
      console.log("Device disconnected, resetting state.");
      activeCallSid = null;
      device.destroy();
      device = null;
    });

    return true;
  } catch (error) {
    console.error('Failed to initialize Twilio:', error);
    showError('Failed to initialize Twilio: ' + error.message);
    return false;
  }
},

async updateBrowserLegCallSid(browserLegCallSid, originalCallSid) {

	const token = localStorage.getItem('authToken');
	console.log(token);
	const testResponse = await fetch(`https://60secondapps-0006.vip:8080/call/dial-status?originalCallSid=${originalCallSid}&browserLegCallSid=${browserLegCallSid}`, {
	      method: "POST",
	      headers: {
			Authorization: `Bearer ${token}`,
	        "Accept": "application/json",
	        "Content-Type": "application/json",
	      },
	      credentials: "omit",
	    });
},

async getLeadDetailsForConnectedCall(originalCallSid) {

	const token = localStorage.getItem('authToken');
	console.log(token);
	const testResponse = await fetch(`https://60secondapps-0006.vip:8080/call/getLeadDetailsForCall?originalCallSid=${originalCallSid}`, {
	      method: "GET",
	      headers: {
			Authorization: `Bearer ${token}`,
	        "Accept": "application/json",
	        "Content-Type": "application/json",
	      },
	      credentials: "omit",
	    })
	    .then(response => response.json())
		.then(data => {
            console.log('Calls dialed for agent:', data);
            //this.callsDialed = data.calls;
        })
        .catch(error => {
            console.error('Error fetching calls:', error);
        });
},

async startAutoDialing() {

	this.startDialing();

  try {
    
    await this.initializeTwilio();

    device.audio.on('ready', () => console.log('Audio ready'));
    device.audio.setAudioConstraints({ echoCancellation: true });
    //device.audio.enable();

    const token = localStorage.getItem('authToken');

    const response = await fetch("https://60secondapps-0006.vip:8080/call/setAutoDialEnabled", {
      method: "POST",
      headers: {
		Authorization: `Bearer ${token}`,
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ 
        token: twilioToken 
      }),
      credentials: "omit",
    });
    const data = await response.text();
    console.log("Calls initiated. ", data);
  } catch (error) {
    console.error("Error initiating calls:", error);
    showError("Failed to start calls: " + error.message);
  }
},

async example1OpenCall() {
  const phoneNumber = document.getElementById('phoneNumber').value;
  if (!phoneNumber) {
    showError('Please enter a phone number');
    return;
  }

  if (!device || device.state !== 'registered') {
    await this.initializeTwilio();
  }

  if (currentCall) {
    console.log('Already in a call, please finish the current call first.');
    showError('Already in a call');
    return;
  }

  console.log("Attempting to connect to phone number:", phoneNumber);
  
  try {
    currentCall = await device.connect({ params: { To: phoneNumber } });
    device.audio.setAudioConstraints({ echoCancellation: true });

    currentCall.on('accept', () => {
      console.log('Call accepted');
    });

    currentCall.on('disconnect', () => {
      console.log('Call ended');
      currentCall = null;
    });

  } catch (error) {
    console.error('Error making call:', error);
    showError('Failed to make call: ' + error.message);
  }
},

async example2InitiateCall() {
  try {
    const phoneNumber2 = document.getElementById('phoneNumber2').value;
    if (!phoneNumber2) {
      showError('Please enter a phone number');
      return;
    }

    const response = await fetch(`https://60secondapps-0010.vip:8080/api/calls/make?to=${encodeURIComponent(phoneNumber2)}&twimlUrl=${encodeURIComponent('')}`, {
      method: 'POST',  
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'omit',
    });

    alert('Call initiated successfully! Call SID: ' + response.data);
  } catch (error) {
    console.error('Error:', error);  // Detailed error log for debugging
    alert('Error: ' + (error.response ? error.response.data : error.message));
  }
}




  },
  mounted() {
    //this.getLeadDetails();
    this.getCallsDialedForAgent();
    
    
  },
  computed: {
    leads() {
      return [
        { name: "John Doe", company: "Tech Corp", title: "CEO", email: "john@techcorp.com", phone: "555-1234" },
        { name: "Jane Smith", company: "Innovate Inc", title: "CTO", email: "jane@innovate.com", phone: "555-5678" },
        { name: "Mike Johnson", company: "Global Solutions", title: "Sales Director", email: "mike@global.com", phone: "555-9012" },
        { name: "Sarah Williams", company: "Digital Dynamics", title: "Marketing Manager", email: "sarah@digital.com", phone: "555-3456" },
        { name: "Tom Brown", company: "Enterprise Systems", title: "VP Operations", email: "tom@enterprise.com", phone: "555-7890" },
        { name: "Emily Davis", company: "Cloud Innovations", title: "Product Manager", email: "emily@cloud.com", phone: "555-2345" },
        { name: "Alex Rodriguez", company: "Data Solutions", title: "Lead Developer", email: "alex@data.com", phone: "555-6789" },
      ];
    },
  },  
};








</script>

<style>
.dialer-lead-info {
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 160px;  /* Ensure it has enough width to fit the content */
  flex-grow: 1;  /* Take up remaining space */
}

.dialer-lead-name {
  font-weight: bold;
  white-space: nowrap;
  min-width: 150px; 
}

.dialer-lead-phone {
  white-space: nowrap;
  margin-left: 10px;  /* Adds padding between phone number and status badge */
}


body {
  background-color: #f4f4f4;
}

.card {
  box-shadow: none;
}

.card-body {
	flex: 1 1 auto;
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
    color: var(--bs-card-color);
}

offset-md-3 {
    margin-left: 25%;
}


.col-md-6 {
    flex: 0 0 auto;
    width: 50%;
}

.dialing-status ul {
  max-height: 200px;
  overflow-y: auto;
}

.connected-lead .card {
  background-color: #e9ecef;
}

.list-group-item .badge {
  //float: right;
}

.list-group-item {
  padding: 10px;
}


/* Brand color customizations */
.btn-primary {
  background-color: #11ba81;
  border-color: #11ba81;
}

.btn-primary:hover {
  background-color: #0f9c6d;
  border-color: #0f9c6d;
}

.btn-start-auto-dialing {
  width: 185px !important;
  border-radius: 15px !important;
  background: #4CAF50;
  color: white;
}

.btn-stop-auto-dialing {
  background-color: lightgrey;
}

.btn-end-call {
    color: #ff0000;
    background-color: #ff000038;
    margin-top: 15px !important;
}

.btn-reachit {
  width: 205px !important;
  border-radius: 15px !important;
  text-align: center; 
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;}

.list-group-item .badge.bg-success {
  background-color: #11ba81 !important;
}

.force-expansion {
  width: calc(100vw - 260px);
}

:root {
  --primary-color: #11ba81;
  --primary-hover: #0f9c6d;
  --background-color: #f4f4f4;
  --card-background: #ffffff;
  --text-color: #333;
  --warning-color: #ffc107;
  --danger-color: #dc3545;
  --secondary-color: #6c757d;
}

.page-title {
  text-align: center;
  margin-bottom: 20px;
  color: var(--text-color);
}

.dialer-wrapper {
  display: flex;
  justify-content: center;
}

.dialer-card {
  width: 100%;
  background-color: var(--card-background);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  padding: 20px;
}

.btn {
  display: block;
  width: 100%;
  padding: 12px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.primary-btn {
  background-color: var(--primary-color);
  color: white;
}

.primary-btn:hover {
  background-color: var(--primary-hover);
}

.secondary-btn {
  background-color: var(--secondary-color);
  color: white;
  margin-top: 15px;
}

.secondary-btn:hover {
  background-color: #555;
}

.danger-btn {
  background-color: var(--danger-color);
  color: white;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.danger-btn:hover {
  background-color: #c82333;
}

.dialing-list {
  list-style: none;
  padding: 0;
  max-height: 200px;
  overflow-y: auto;
}

.dialing-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

li {
  list-style-type: none;
}

.badge {
    font-weight: bold;
    padding: 3px 6px;
    color: white;
    border-radius: 4px;
    /* font-size: 12px; */
    background-color: #FFC107;
}

.warning-badge {
  background-color: var(--warning-color);
  color: black;
}

.connected-lead {
  background-color: #f8f9fa;
  border-radius: 6px;
  padding: 15px;
  text-align: left;  /* Ensures text alignment is left */
  display: flex;
  flex-direction: column;  /* Aligns content vertically */
  align-items: flex-start;  /* Aligns content to the left */
  width: 100%;  /* Ensures it takes up full width */
}

.connected-lead .card {
  width: 100%;  /* Ensures the card stretches to full width */
}

.phone-icon {
  margin-right: 10px;
  font-size: 20px;
}

.list-group-item .badge {
  display: flex;
  align-items: center;
  justify-content: center;
}

.list-group-item {
  display: flex;
  align-items: center;  /* Vertically center all content */
  justify-content: space-between;
  padding: 10px;
  height: 50px; /* Uniform row height */
  gap: 10px;
}

.progress {
  height: 20px;
}

.progress-container {
  width: 120px;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center; /* Ensures the progress bar is vertically centered */
  margin-bottom: 15px;
}

.progress-bar {
  background-color: #11ba81;
  height: 100%;
  transition: width 0.5s ease;
}

.btn-end-call {
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialing-buttons {
  display: flex;
  align-items: center;  /* Ensures buttons align vertically */
  justify-content: center; /* Centers the buttons within the container */
  margin-left: 10px;  /* Adds spacing to the left of the button */
}

.dialing-buttons button:focus {
  outline: none;
}

.dialing-buttons button {
  height: 35px;
  min-width: 80px;  /* Keeps button a consistent size */
  padding: 5px 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}

.status-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  padding: 5px 10px;
  margin-right: 10px;  /* Adds space between badge and phone number */
}





input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

button {
  background: #3498db;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s ease;
  margin-right: 1rem;
}

button:hover {
  background: #2980b9;
}

.call-logs {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid #eee;
}

ul {
  list-style: none;
}

li {
  padding: 0.5rem;
  border-bottom: 1px solid #eee;
}

.status-indicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
}

.status-active { background: #2ecc71; }
.status-completed { background: #3498db; }
.status-failed { background: #e74c3c; }

#errorMessage {
  color: #e74c3c;
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 4px;
  background: #fde2e2;
  display: none;
}

.example-div {
	max-width: 600px;
	margin-left: 75px;
	background-color: lightgrey;
}
.example-div input {
	max-width: 200px;
	margin-bottom: 7px;
	margin-right: 7px;
	border-radius: 15px;
}

.example-div button {
	border-radius: 15px;
	font-weight: bold;
	
}

</style>

<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card>
          <template slot="header">
            <div class="row justify-content-between align-items-between mx-3">

              <h5 class="card-title">Phone Numbers</h5>
              
              <div>
                  <base-button class="btn btn-primary" @click="modalPhoneNumbers = true">Add</base-button>
              </div>
              
              <modal :show.sync="modalPhoneNumbers">
                <template slot="header">
                  <h5 class="modal-title" id="exampleModalLabel">Add PhoneNumber</h5>
                </template>
                <div>
                  <form @submit.prevent>
  <base-input label="FriendlyName" type="text" placeholder="Enter FriendlyName" v-model="phoneNumberToAdd.friendlyName"></base-input>
  <base-input label="CreatedOn" type="text" placeholder="Enter CreatedOn" v-model="phoneNumberToAdd.createdOn"></base-input>
  <base-input label="LastModified" type="text" placeholder="Enter LastModified" v-model="phoneNumberToAdd.lastModified"></base-input>
  <base-input label="Status" type="text" placeholder="Enter Status" v-model="phoneNumberToAdd.status"></base-input>
  <base-input label="State" type="text" placeholder="Enter State" v-model="phoneNumberToAdd.state"></base-input>
  <base-input label="PhoneNumber" type="text" placeholder="Enter PhoneNumber" v-model="phoneNumberToAdd.phoneNumber"></base-input>
  <base-input label="PhoneNumberId" type="text" placeholder="Enter PhoneNumberId" v-model="phoneNumberToAdd.phoneNumberId"></base-input>
                  </form>
                </div>
                <template slot="footer">
                  <base-button type="primary" @click="handleAddSubmitted()">Save</base-button>
                </template>
              </modal>
            </div>
          </template>
<div>
          <span class="table-title">PhoneNumbers</span>
          <span>({{this.totalElements}})</span>
          <span><a-pagination
				      :current="pagination.current"
				      :total="pagination.total"
				      :pageSize="pagination.pageSize"
				      @change="onTableChange"
				      show-size-changer
				      :pageSizeOptions="['5','10', '20', '30', '40']"
				      @showSizeChange="handleSizeChange"
				    /></span>
</div>
          <!-- Conditionally render the view based on the 'isTableView' flag -->
          <div v-if="isTableView">
            <!-- Render the existing Table View -->
            <a-table :columns="columns" :data-source="phoneNumbers" :row-key="record => record.PhoneNumberId" :pagination="pagination"
              :loading="searchLoading" @change="onTableChange" :scroll="{ x: 'max-content' }">




             <template slot="lastModified" slot-scope="dataIndex">
              	{{ formatTime(dataIndex) }}
              </template>
              <template slot="createdOn" slot-scope="dataIndex">
              	{{ formatTime(dataIndex) }}
              </template>
              <template slot="endedOn" slot-scope="dataIndex">
              	{{ formatTime(dataIndex) }}
              </template>
              <template slot="blackOutStartDate" slot-scope="dataIndex">
              	{{ formatDate(dataIndex) }}
              </template>
            </a-table>
          </div>
          <div v-else>
            <!-- Render the Picture View  -->
            <PhoneNumberPictureView :phoneNumbers="phoneNumbers" />
          </div>

        </card>
      </div>
    </div>

  </div>
</template>

<script>
import Modal from "@/components/Modal";
import BaseButton from "@/components/BaseButton";
import BaseInput from "@/components/BaseInput";
import NotificationTemplate from "@/pages/Notifications/NotificationTemplate";
import { Card } from "@/components/Card";
import PhoneNumberService from "../services/PhoneNumberService";
import { ASelect, ASelectOption, AButton, Table, Pagination } from "ant-design-vue";
import PhoneNumberPictureView from './PhoneNumberPictureView.vue';


const phoneNumbersColumns = [
  "phoneNumberId",
  "phoneNumberId"
]

export default {
  props: {
    phoneNumbers: {
      type: Array,
      required: true,
    },
    totalElements: {
      type: Number,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
  },
  components: {
    Card,
    Modal,
    BaseButton,
    BaseInput,
    Table,

    Pagination,
    PhoneNumberPictureView
  },

  data() {
    return {
      modalPhoneNumbers: false,
        isTableView: true,

      columns: [
        {
          title: 'Phone Number Id',
		dataIndex: 'phoneNumberId',
          visible: true,
          scopedSlots: { customRender: 'phoneNumberId' },
          sorter: true
          //sorter: (a, b) => a.phoneNumberId - b.phoneNumberId,
          //sorter: (a, b) => a.phoneNumberId.localeCompare(b.phoneNumberId),
        },
        {
          title: 'Phone Number',
		dataIndex: 'phoneNumber',
          visible: true,
          scopedSlots: { customRender: 'phoneNumber' },
          sorter: true
          //sorter: (a, b) => a.phoneNumber - b.phoneNumber,
          //sorter: (a, b) => a.phoneNumber.localeCompare(b.phoneNumber),
        },
        {
          title: 'Friendly Name',
		dataIndex: 'friendlyName',
          visible: true,
          scopedSlots: { customRender: 'friendlyName' },
          sorter: true
          //sorter: (a, b) => a.friendlyName - b.friendlyName,
          //sorter: (a, b) => a.friendlyName.localeCompare(b.friendlyName),
        },
        {
          title: 'State',
		dataIndex: 'state',
          visible: true,
          scopedSlots: { customRender: 'state' },
          sorter: true
          //sorter: (a, b) => a.state - b.state,
          //sorter: (a, b) => a.state.localeCompare(b.state),
        },
        {
          title: 'Status',
		dataIndex: 'status',
          visible: true,
          scopedSlots: { customRender: 'status' },
          sorter: true
          //sorter: (a, b) => a.status - b.status,
          //sorter: (a, b) => a.status.localeCompare(b.status),
        },
        {
          title: 'Created On',
		dataIndex: 'createdOn',
          visible: true,
          scopedSlots: { customRender: 'createdOn' },
          sorter: true
          //sorter: (a, b) => a.createdOn - b.createdOn,
          //sorter: (a, b) => a.createdOn.localeCompare(b.createdOn),
        },
        {
          title: 'Last Modified',
		dataIndex: 'lastModified',
          visible: true,
          scopedSlots: { customRender: 'lastModified' },
          sorter: true
          //sorter: (a, b) => a.lastModified - b.lastModified,
          //sorter: (a, b) => a.lastModified.localeCompare(b.lastModified),
        },
      ],
      pagination: {
        current: 1,
        pageSize: 50,
        total: 0,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total) => `Total ${total} phoneNumbers`,
      },

      phoneNumbers: [],
      phoneNumberToAdd: {},

      phoneNumbersTable: {
        columns: [...phoneNumbersColumns],
        data: [],
      },

      // New properties for sorting and searching
      sortBy: 'phoneNumberId',           // Column to sort by
      sortOrder: 'asc',     // Sort order (asc or desc)
      searchLoading: false, // Initialize searchLoading property


    };
  },

  methods: {

    async renderPhoneNumbersTable() {
      this.searchLoading = true; // Set searchLoading to true while fetching data
      this.searchLoading = false;

      this.pagination.total = this.totalElements;
      this.pagination.current = this.page;

      let phoneNumbersTableData = [];
      for (let i = 0; i < this.phoneNumbers.length; i++) {
        phoneNumbersTableData.push({
          id: i,
          phoneNumberId: this.phoneNumbers[i].phoneNumberId,
          phoneNumberId: this.phoneNumbers[i].phoneNumberId,
        });

      }
      this.searchLoading = false;
    },

    async onTableChange(pagination, filters, sorter) {
      if (sorter && sorter.field && sorter.order) {
        this.sortBy = sorter.field;
        if (sorter.order == "ascend") {
            this.sortOrder = "asc";
        } else {
            this.sortOrder = "desc";
        }
      }
      if (pagination) {
        this.pagination.current = pagination.current;
        this.pagination.pageSize = pagination.pageSize;
      }

	  this.$emit('get-all-phone-numbers',this.sortBy,this.sortOrder,this.pagination.current-1,this.pagination.pageSize);
      this.handleTableChanged()
    },
	
	initializeColumns() {
        this.columns = this.columns.filter(item => item.visible);
    },

    routingToPhoneNumberDetail(id) {
      this.$router.push({ name: 'PhoneNumberDetail', params: { phoneNumberId: id.toString() }})
    },
    routingToPhoneNumberAssignmentDetail(id) {
      this.$router.push({ name: 'PhoneNumberAssignmentDetail', params: { phoneNumberAssignmentId: id.toString() }})
    },
    routingToLeadListDetail(id) {
      this.$router.push({ name: 'LeadListDetail', params: { leadListId: id.toString() }})
    },
    routingToActivityLogDetail(id) {
      this.$router.push({ name: 'ActivityLogDetail', params: { activityLogId: id.toString() }})
    },
    routingToCarrierDetail(id) {
      this.$router.push({ name: 'CarrierDetail', params: { carrierId: id.toString() }})
    },
    routingToLeadDetail(id) {
      this.$router.push({ name: 'LeadDetail', params: { leadId: id.toString() }})
    },
    routingToCallDetail(id) {
      this.$router.push({ name: 'CallDetail', params: { callId: id.toString() }})
    },
    
    toggleView() {
      this.isTableView = !this.isTableView;
    },
	
	async handleAddSubmitted() {
      this.modalPhoneNumbers = false;

      const currentDate = new Date().getTime();
      this.phoneNumberToAdd.created = currentDate;

      const jsonData = JSON.stringify(this.phoneNumberToAdd);
      console.log(jsonData);
      
      const res = await PhoneNumberService.addPhoneNumber(jsonData);

      if (res.status === 200) {
        this.$notify({
          component: NotificationTemplate,
          icon: "tim-icons icon-bell-55",
          type: "success",
          timeout: 3000,
        });
      }
	},
	
	handleTableChanged() {
	},
	
	formatTime(dateString) {
      if(dateString !== null){
        const date = new Date(dateString);
      return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')} EST`;
      }
      // Format the date here as needed, for example:
    },  
    
 formatDate(dateString) {
    if (dateString !== null) {
	    const date = new Date(dateString);
	    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	    const day = String(date.getDate()).padStart(2, '0');
	    const monthAbbreviation = months[date.getMonth()];
	    const year = date.getFullYear();
	    return `${day} ${monthAbbreviation} ${year}`;
  	}
  	// Handle the case when dateString is null or invalid
  	return '';
    
   },

  },
  mounted() {
  	this.initializeColumns();
    this.renderPhoneNumbersTable();
  }
};
</script>

<style>
.modal-dialog {
  margin-top: -300px;
}
.ant-table-row  {
	text-align: center;
}
.ant-table-thead th span {
	text-align: center;
	width: 100%
}
.ant-table-fixed td span {
    text-align: center;
}

.table-title {
	font-size: 1.75rem;
	font-weight: bold;
	font-style: italic;
	color: #11ba81;
	text-align: left;
	padding: 0px 15px;
}

.phoneNumber-link {
	color: #11ba81;
}

</style>

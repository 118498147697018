import http from "../http-common"; 

class ActivityLogService {
  getAllActivityLogs(searchDTO) {
    console.log(searchDTO)
    return this.getRequest(`/activityLog/activityLogs`, searchDTO);
  }
 

  get(activityLogId) {
    return this.getRequest(`/activityLog/${activityLogId}`, null);
  }

  findByField(matchData) {
    return this.getRequest(`/activityLog?field=${matchData}`, null);
  }

  addActivityLog(data) {
    return http.post("/activityLog/addActivityLog", data);
  }

  update(data) {
  	return http.post("/activityLog/updateActivityLog", data);
  }
  
  uploadImage(data,activityLogId) {
  	return http.postForm("/activityLog/uploadImage/"+activityLogId, data);
  }




	postRequest(url, data) {
        const token = localStorage.getItem('authToken');
        if (token) {
            return http.post(url, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
        }
      };

	getRequest(url, params) {
        const token = localStorage.getItem('authToken');
        if (token) {
            return http.get(url, {
            	params: params,
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
        }
    };

}

export default new ActivityLogService();

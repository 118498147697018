
<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card>
          <template slot="header">
            <div class="row justify-content-between align-items-between mx-3">

              <h5 class="card-title">Phone Number Assignments</h5>
              
              <div>
                  <base-button class="btn btn-primary" @click="modalPhoneNumberAssignments = true">Add</base-button>
              </div>
              
              <modal :show.sync="modalPhoneNumberAssignments">
                <template slot="header">
                  <h5 class="modal-title" id="exampleModalLabel">Add PhoneNumberAssignment</h5>
                </template>
                <div>
                  <form @submit.prevent>
  <base-input label="CreatedOn" type="text" placeholder="Enter CreatedOn" v-model="phoneNumberAssignmentToAdd.createdOn"></base-input>
  <base-input label="IsActive" type="text" placeholder="Enter IsActive" v-model="phoneNumberAssignmentToAdd.isActive"></base-input>
  <base-input label="PhoneNumberAssignmentId" type="text" placeholder="Enter PhoneNumberAssignmentId" v-model="phoneNumberAssignmentToAdd.phoneNumberAssignmentId"></base-input>
                  </form>
                </div>
                <template slot="footer">
                  <base-button type="primary" @click="handleAddSubmitted()">Save</base-button>
                </template>
              </modal>
            </div>
          </template>
<div>
          <span class="table-title">PhoneNumberAssignments</span>
          <span>({{this.totalElements}})</span>
          <span><a-pagination
				      :current="pagination.current"
				      :total="pagination.total"
				      :pageSize="pagination.pageSize"
				      @change="onTableChange"
				      show-size-changer
				      :pageSizeOptions="['5','10', '20', '30', '40']"
				      @showSizeChange="handleSizeChange"
				    /></span>
</div>
          <!-- Conditionally render the view based on the 'isTableView' flag -->
          <div v-if="isTableView">
            <!-- Render the existing Table View -->
            <a-table :columns="columns" :data-source="phoneNumberAssignments" :row-key="record => record.PhoneNumberAssignmentId" :pagination="pagination"
              :loading="searchLoading" @change="onTableChange" :scroll="{ x: 'max-content' }">



	<template slot="isActive" slot-scope="dataIndex">
       {{ dataIndex ? 'Yes' : 'No' }}
   </template>

             <template slot="lastModified" slot-scope="dataIndex">
              	{{ formatTime(dataIndex) }}
              </template>
              <template slot="createdOn" slot-scope="dataIndex">
              	{{ formatTime(dataIndex) }}
              </template>
              <template slot="endedOn" slot-scope="dataIndex">
              	{{ formatTime(dataIndex) }}
              </template>
              <template slot="blackOutStartDate" slot-scope="dataIndex">
              	{{ formatDate(dataIndex) }}
              </template>
            </a-table>
          </div>
          <div v-else>
            <!-- Render the Picture View  -->
            <PhoneNumberAssignmentPictureView :phoneNumberAssignments="phoneNumberAssignments" />
          </div>

        </card>
      </div>
    </div>

  </div>
</template>

<script>
import Modal from "@/components/Modal";
import BaseButton from "@/components/BaseButton";
import BaseInput from "@/components/BaseInput";
import NotificationTemplate from "@/pages/Notifications/NotificationTemplate";
import { Card } from "@/components/Card";
import PhoneNumberAssignmentService from "../services/PhoneNumberAssignmentService";
import { ASelect, ASelectOption, AButton, Table, Pagination } from "ant-design-vue";
import PhoneNumberAssignmentPictureView from './PhoneNumberAssignmentPictureView.vue';


const phoneNumberAssignmentsColumns = [
  "phoneNumberAssignmentId",
  "phoneNumberAssignmentId"
]

export default {
  props: {
    phoneNumberAssignments: {
      type: Array,
      required: true,
    },
    totalElements: {
      type: Number,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
  },
  components: {
    Card,
    Modal,
    BaseButton,
    BaseInput,
    Table,

    Pagination,
    PhoneNumberAssignmentPictureView
  },

  data() {
    return {
      modalPhoneNumberAssignments: false,
        isTableView: true,

      columns: [
        {
          title: 'Phone Number Assignment Id',
		dataIndex: 'phoneNumberAssignmentId',
          visible: true,
          scopedSlots: { customRender: 'phoneNumberAssignmentId' },
          sorter: true
          //sorter: (a, b) => a.phoneNumberAssignmentId - b.phoneNumberAssignmentId,
          //sorter: (a, b) => a.phoneNumberAssignmentId.localeCompare(b.phoneNumberAssignmentId),
        },
        {
          title: 'Is Active',
		dataIndex: 'isActive',
          visible: true,
          scopedSlots: { customRender: 'isActive' },
          sorter: true
          //sorter: (a, b) => a.isActive - b.isActive,
          //sorter: (a, b) => a.isActive.localeCompare(b.isActive),
        },
        {
          title: 'Created On',
		dataIndex: 'createdOn',
          visible: true,
          scopedSlots: { customRender: 'createdOn' },
          sorter: true
          //sorter: (a, b) => a.createdOn - b.createdOn,
          //sorter: (a, b) => a.createdOn.localeCompare(b.createdOn),
        },
      ],
      pagination: {
        current: 1,
        pageSize: 50,
        total: 0,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total) => `Total ${total} phoneNumberAssignments`,
      },

      phoneNumberAssignments: [],
      phoneNumberAssignmentToAdd: {},

      phoneNumberAssignmentsTable: {
        columns: [...phoneNumberAssignmentsColumns],
        data: [],
      },

      // New properties for sorting and searching
      sortBy: 'phoneNumberAssignmentId',           // Column to sort by
      sortOrder: 'asc',     // Sort order (asc or desc)
      searchLoading: false, // Initialize searchLoading property


    };
  },

  methods: {

    async renderPhoneNumberAssignmentsTable() {
      this.searchLoading = true; // Set searchLoading to true while fetching data
      this.searchLoading = false;

      this.pagination.total = this.totalElements;
      this.pagination.current = this.page;

      let phoneNumberAssignmentsTableData = [];
      for (let i = 0; i < this.phoneNumberAssignments.length; i++) {
        phoneNumberAssignmentsTableData.push({
          id: i,
          phoneNumberAssignmentId: this.phoneNumberAssignments[i].phoneNumberAssignmentId,
          phoneNumberAssignmentId: this.phoneNumberAssignments[i].phoneNumberAssignmentId,
        });

      }
      this.searchLoading = false;
    },

    async onTableChange(pagination, filters, sorter) {
      if (sorter && sorter.field && sorter.order) {
        this.sortBy = sorter.field;
        if (sorter.order == "ascend") {
            this.sortOrder = "asc";
        } else {
            this.sortOrder = "desc";
        }
      }
      if (pagination) {
        this.pagination.current = pagination.current;
        this.pagination.pageSize = pagination.pageSize;
      }

	  this.$emit('get-all-phone-number-assignments',this.sortBy,this.sortOrder,this.pagination.current-1,this.pagination.pageSize);
      this.handleTableChanged()
    },
	
	initializeColumns() {
        this.columns = this.columns.filter(item => item.visible);
    },

    routingToPhoneNumberDetail(id) {
      this.$router.push({ name: 'PhoneNumberDetail', params: { phoneNumberId: id.toString() }})
    },
    routingToPhoneNumberAssignmentDetail(id) {
      this.$router.push({ name: 'PhoneNumberAssignmentDetail', params: { phoneNumberAssignmentId: id.toString() }})
    },
    routingToLeadListDetail(id) {
      this.$router.push({ name: 'LeadListDetail', params: { leadListId: id.toString() }})
    },
    routingToActivityLogDetail(id) {
      this.$router.push({ name: 'ActivityLogDetail', params: { activityLogId: id.toString() }})
    },
    routingToCarrierDetail(id) {
      this.$router.push({ name: 'CarrierDetail', params: { carrierId: id.toString() }})
    },
    routingToLeadDetail(id) {
      this.$router.push({ name: 'LeadDetail', params: { leadId: id.toString() }})
    },
    routingToCallDetail(id) {
      this.$router.push({ name: 'CallDetail', params: { callId: id.toString() }})
    },
    
    toggleView() {
      this.isTableView = !this.isTableView;
    },
	
	async handleAddSubmitted() {
      this.modalPhoneNumberAssignments = false;

      const currentDate = new Date().getTime();
      this.phoneNumberAssignmentToAdd.created = currentDate;

      const jsonData = JSON.stringify(this.phoneNumberAssignmentToAdd);
      console.log(jsonData);
      
      const res = await PhoneNumberAssignmentService.addPhoneNumberAssignment(jsonData);

      if (res.status === 200) {
        this.$notify({
          component: NotificationTemplate,
          icon: "tim-icons icon-bell-55",
          type: "success",
          timeout: 3000,
        });
      }
	},
	
	handleTableChanged() {
	},
	
	formatTime(dateString) {
      if(dateString !== null){
        const date = new Date(dateString);
      return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')} EST`;
      }
      // Format the date here as needed, for example:
    },  
    
 formatDate(dateString) {
    if (dateString !== null) {
	    const date = new Date(dateString);
	    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	    const day = String(date.getDate()).padStart(2, '0');
	    const monthAbbreviation = months[date.getMonth()];
	    const year = date.getFullYear();
	    return `${day} ${monthAbbreviation} ${year}`;
  	}
  	// Handle the case when dateString is null or invalid
  	return '';
    
   },

  },
  mounted() {
  	this.initializeColumns();
    this.renderPhoneNumberAssignmentsTable();
  }
};
</script>

<style>
.modal-dialog {
  margin-top: -300px;
}
.ant-table-row  {
	text-align: center;
}
.ant-table-thead th span {
	text-align: center;
	width: 100%
}
.ant-table-fixed td span {
    text-align: center;
}

.table-title {
	font-size: 1.75rem;
	font-weight: bold;
	font-style: italic;
	color: #11ba81;
	text-align: left;
	padding: 0px 15px;
}

.phoneNumberAssignment-link {
	color: #11ba81;
}

</style>
